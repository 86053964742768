import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';
import backendApi from './model/api';

// constants
export const streamConstants = {
    LIVE_STREAM_LIST_REQ: 'LIVE_STREAM_LIST_REQ',
    LIVE_STREAM_LIST_RESPONSE: 'LIVE_STREAM_LIST_RESPONSE',
    LIVE_STREAM_LIST_FAILURE: 'LIVE_STREAM_LIST_FAILURE',
    LIVE_STREAM_LIST_REQ_FOR_LIVE_UPCOMING: 'LIVE_STREAM_LIST_REQ_FOR_LIVE_UPCOMING',
    LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_SUCCESS: 'LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_SUCCESS',
    LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_FAILURE: 'LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_FAILURE',

    LIVE_STREAM_LIST_REQ_FOR_PAST_STREAM: 'LIVE_STREAM_LIST_REQ_FOR_PAST_STREAM',
    LIVE_STREAM_LIST_FOR_PAST_STREAM_SUCCESS: 'LIVE_STREAM_LIST_FOR_PAST_STREAM_SUCCESS',
    LIVE_STREAM_LIST_FOR_PAST_STREAM_FAILURE: 'LIVE_STREAM_LIST_FOR_PAST_STREAM_FAILURE',

    CATEGORIES_LIST_REQ: 'CATEGORIES_LIST_REQ',
    CATEGORIES_LIST_REQ_SUCCESS: 'CATEGORIES_LIST_REQ_SUCCESS',
    CATEGORIES_LIST_REQ_FAILURE: 'CATEGORIES_LIST_REQ_FAILURE',

    SHOW_ALL_LIST_VIDEOS: 'SHOW_ALL_LIST_VIDEOS',
    SHOW_ALL_LIST_VIDEOS_SUCCESS: 'SHOW_ALL_LIST_VIDEOS_SUCCESS',
    SHOW_ALL_LIST_VIDEOS_FAILURE: 'SHOW_ALL_LIST_VIDEOS_FAILURE',

    SHOW_MORE_LIST_DATA: 'SHOW_MORE_LIST_DATA',
    SHOW_MORE_LIST_DATA_SUCCESS: 'SHOW_MORE_LIST_DATA_SUCCESS',
    SHOW_MORE_LIST_DATA_FAILURE: 'SHOW_MORE_LIST_DATA_FAILURE',

    LIVE_STREAM_LIKE_REQ: 'LIVE_STREAM_LIKE_REQ',
    LIVE_STREAM_LIKE_REQ_SUCCESS: 'LIVE_STREAM_LIKE_REQ_SUCCESS',
    LIVE_STREAM_LIKE_REQ_FAILURE: 'LIVE_STREAM_LIKE_REQ_FAILURE',

    SINGLE_STREAM_DETAIL: 'SINGLE_STREAM_DETAIL',
    SINGLE_STREAM_DETAIL_SUCCESS: 'SINGLE_STREAM_DETAIL_SUCCESS',
    SINGLE_STREAM_DETAIL_FAILURE: 'SINGLE_STREAM_DETAIL_FAILURE',

};

export const streamActions = {};
// actions

streamActions.promotedVideos = payload => ({
    type: streamConstants.LIVE_STREAM_LIST_REQ,
    payload,
});

streamActions.liveAndUpcoming = payload => ({
    type: streamConstants.LIVE_STREAM_LIST_REQ_FOR_LIVE_UPCOMING,
    payload,
});

streamActions.pastStreams = payload => ({
    type: streamConstants.LIVE_STREAM_LIST_REQ_FOR_PAST_STREAM,
    payload,
});

streamActions.categories = payload => ({
    type: streamConstants.CATEGORIES_LIST_REQ,
    payload,
});

streamActions.listAllView = payload => ({
    type: streamConstants.SHOW_ALL_LIST_VIDEOS,
    payload,
});

streamActions.loadViewItems = payload => ({
    type: streamConstants.SHOW_MORE_LIST_DATA,
    payload,
});

streamActions.likeLiveStream = payload => ({
    type: streamConstants.LIVE_STREAM_LIKE_REQ,
    payload,
});

streamActions.liveStreamData = payload => ({
    type: streamConstants.SINGLE_STREAM_DETAIL,
    payload,
});


// The initial state of the App
export const initialState = {
    liveStream: [],
    liveAndUpcoming: [],
    pastStream: [],
    categories: [],
    limit: 10,
    sort: 'startDateAndTime',
    sortType: 'asc',
    sortTypeforPast: 'desc',
    sortTypeforUpcoming: 'asc',
    page: 1,
    count: 0,
    status: '',
    listView: [],
    isNext: '',
    isViewAllLoader: false,
    categoryLoader: false,
    isCategory: '',
    loadPage: false,
    likeResponse: '',
    singleStreamData: '',
    streamLoader: false,
};

// reducers
const appReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case streamConstants.LIVE_STREAM_LIST_REQ:
                draft.isCategory = action.payload.category;
                draft.sortType = action.payload.sortType;
                draft.loadPage = true;
                break;
            case streamConstants.LIVE_STREAM_LIST_REQ_FOR_LIVE_UPCOMING:
                draft.isCategory = action.payload.category;
                draft.sortTypeforUpcoming = action.payload.sortType;
                break;
            case streamConstants.LIVE_STREAM_LIST_REQ_FOR_PAST_STREAM:
                draft.isCategory = action.payload.category;
                draft.sortTypeforPast = action.payload.sortType;
                break;
            case streamConstants.SHOW_MORE_LIST_DATA:
                draft.limit = action.payload.limit;
                draft.status = action.payload.status;
                draft.isViewAllLoader = true;
                break;
            case streamConstants.SHOW_ALL_LIST_VIDEOS:
                draft.sortType = action.payload.sortType;
                draft.limit = action.payload.a?.limit;
                draft.isViewAllLoader = true;
                break;
            case streamConstants.SHOW_ALL_LIST_VIDEOS_SUCCESS:
                draft.listView = action.payload.data;
                draft.isNext = action.payload.isNext;
                draft.isViewAllLoader = false;
                break;
            case streamConstants.SHOW_ALL_LIST_VIDEOS_FAILURE:
                draft.listView = action.payload.data;
                draft.isNext = action.payload.isNext;
                draft.isViewAllLoader = false;
                break;
            case streamConstants.LIVE_STREAM_LIST_RESPONSE:
                draft.liveStream = action.payload.data;
                draft.loadPage = false;
                break;
            case streamConstants.LIVE_STREAM_LIST_FAILURE:
                draft.loadPage = false;
                break;
            case streamConstants.LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_SUCCESS:
                draft.liveAndUpcoming = action.payload.data;
                break;
            case streamConstants.LIVE_STREAM_LIST_FOR_PAST_STREAM_SUCCESS:
                draft.pastStream = action.payload.data;
                break;
            case streamConstants.CATEGORIES_LIST_REQ:
                draft.categoryLoader = true;
                break;
            case streamConstants.CATEGORIES_LIST_REQ_SUCCESS:
                draft.categories = action.payload;
                draft.categoryLoader = false;
                break;
            case streamConstants.CATEGORIES_LIST_REQ_FAILURE:
                draft.categories = action.payload;
                draft.categoryLoader = false;
                break;
            case streamConstants.LIVE_STREAM_LIKE_REQ:
                draft.likeLoader = true;
                break;
            case streamConstants.LIVE_STREAM_LIKE_REQ_SUCCESS:
                draft.likeLoader = false;
                draft.likeResponse = action.payload;
                break;
            case streamConstants.LIVE_STREAM_LIKE_REQ_FAILURE:
                draft.likeLoader = false;
                draft.likeResponse = action.payload;
                break;
            case streamConstants.SINGLE_STREAM_DETAIL:
                draft.streamLoader = true;
                break;
            case streamConstants.SINGLE_STREAM_DETAIL_SUCCESS:
                draft.singleStreamData = action.payload;
                draft.streamLoader = false;
                break;
            case streamConstants.SINGLE_STREAM_DETAIL_FAILURE:
                draft.singleStreamData = action.payload;
                draft.streamLoader = false;
                break;
            default:
                break;
        }
    });
export default appReducer;

export const getAllState = state => state.stream;

function* liveStreamList(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.liveStreamsAPI(i, props.payload);

        if (res.success)
            yield put({ type: streamConstants.LIVE_STREAM_LIST_RESPONSE, payload: res });
        else
            yield put({ type: streamConstants.LIVE_STREAM_LIST_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.LIVE_STREAM_LIST_FAILURE, payload: false });
    }
}

function* liveStreamListforLiveUpcoming(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.liveStreamsAPI(i, props.payload);

        if (res.success)
            yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_LIVE_UPCOMING_FAILURE, payload: false });
    }
}

function* liveStreamListforPast(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.liveStreamsAPI(i, props.payload);

        if (res.success)
            yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_PAST_STREAM_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_PAST_STREAM_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.LIVE_STREAM_LIST_FOR_PAST_STREAM_FAILURE, payload: false });
    }
}

function* categoryReq(props) {
    try {
        const res = yield backendApi.categoryListApi(props.payload);

        if (res.success)
            yield put({ type: streamConstants.CATEGORIES_LIST_REQ_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.CATEGORIES_LIST_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.CATEGORIES_LIST_REQ_FAILURE, payload: false });
    }
}

function* listAllVideoReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.listAllVideoAPI(i, props.payload);

        if (res.success)
            yield put({ type: streamConstants.SHOW_ALL_LIST_VIDEOS_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.SHOW_ALL_LIST_VIDEOS_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.SHOW_ALL_LIST_VIDEOS_FAILURE, payload: false });
    }
}

function* likeLiveStreamReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.likeLiveStreamAPI(props.payload);

        if (res.success)
            yield put({ type: streamConstants.LIVE_STREAM_LIKE_REQ_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.LIVE_STREAM_LIKE_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.LIVE_STREAM_LIKE_REQ_FAILURE, payload: false });
    }
}

function* StreamDetailReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.streamDetailApi(props.payload);

        if (res.success)
            yield put({ type: streamConstants.SINGLE_STREAM_DETAIL_SUCCESS, payload: res });
        else
            yield put({ type: streamConstants.SINGLE_STREAM_DETAIL_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: streamConstants.SINGLE_STREAM_DETAIL_FAILURE, payload: false });
    }
}

export const streamSagas = [
    takeLatest(streamConstants.LIVE_STREAM_LIST_REQ, liveStreamList),
    takeLatest(streamConstants.LIVE_STREAM_LIST_REQ_FOR_LIVE_UPCOMING, liveStreamListforLiveUpcoming),
    takeLatest(streamConstants.LIVE_STREAM_LIST_REQ_FOR_PAST_STREAM, liveStreamListforPast),
    takeLatest(streamConstants.CATEGORIES_LIST_REQ, categoryReq),
    takeLatest(streamConstants.SHOW_ALL_LIST_VIDEOS, listAllVideoReq),
    takeLatest(streamConstants.SHOW_MORE_LIST_DATA, listAllVideoReq),
    takeLatest(streamConstants.LIVE_STREAM_LIKE_REQ, likeLiveStreamReq),
    takeLatest(streamConstants.SINGLE_STREAM_DETAIL, StreamDetailReq)
];