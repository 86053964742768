
let S3_BUCKET_URL = process.env.REACT_APP_S3_BUCKET;
const REGION = 'ap-southeast-2';
const ACCESS_KEY = 'AKIAWUY3RA7FSBY4257Q';
const SECRET_ACCESS_KEY = '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';

export const config = {
    bucketName: S3_BUCKET_URL,
    region: REGION,
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
}
