import React, { useState } from 'react'
import '../SignUp/index.css'
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GoogleIcon } from '../../sharedUtils/globalIcons'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import OtpInput from 'react-otp-input';
import { createUseStyles } from 'react-jss';
import ReactSelect from 'react-select';
import { GoogleLogin } from 'react-google-login';

const google = window.google;

const useStyles = createUseStyles({
    otpInputStyle: {
        width: '35px !important',
        height: '35px !important',
        margin: '0 0.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
        background: '#eff0f4',
        border: '1px solid #53b029',
        color: '#53b029',
        borderRadius: '3px',
        padding: '0 !important'
    },
});

const EditProfile = (props) => {

    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    const [results, setResults] = useState([]);

    let addressList = [];

    addressList = results.map(e => ({
        label: `${e.description}`,
        value: e.place_id,
    }));

    const setPossiblePlacesFunc = address => {
        // Pass the token to the autocomplete service.
        var autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: address,
                sessionToken: sessionToken,
            },
            displaySuggestions => {
                if (displaySuggestions) {
                    setResults(
                        displaySuggestions.map(e => ({
                            description: e.description,
                            place_id: e.place_id,
                        })),
                    );
                }
            },
        );
    };

    const classes = useStyles();
    const customProps = props;

    const [isMobile, setIsMobile] = useState();
    const [isOTP, setIsOTP] = useState('');

    const handlePhone = (country, phoneNumber) => {
        const phone = {
            countryCode: '+' + country.dialCode,
            mobile: phoneNumber.replace(country.dialCode, ''),
        }
        setIsMobile(phone);
    }

    const handleChangeOTP = (otp) => {
        setIsOTP(otp);
    }

    return (
        <>
            <div className='login_form'>
                <Formik
                    initialValues={{ phone: '' }}
                    onSubmit={(values) => {
                        const obj = {
                            // ...values,
                            otp: isOTP,
                            mobile: isMobile.mobile,
                            countryCode: isMobile.countryCode,
                            fName: values.firstName,
                            // lName: values.firstName,
                            // email: values.email,
                            location: {
                                address: values.address.label,
                                placeId: values.address.value,
                                type: 'Point',
                                coordinates: [],
                            }
                        }
                        props.handleVerifyOTP(obj);
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {!customProps.otpStatus &&
                                <>
                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Enter your first and last name</span>
                                        </div>
                                        <div className='custom_col'>
                                            <input
                                                type='text'
                                                name='firstName'
                                                id='firstName'
                                                placeholder='David Beckham'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Enter your mobile number</span>
                                        </div>
                                        <div className='custom_col'>
                                            <PhoneInput
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                country={'au'}
                                                name='phone'
                                                id='phone'
                                                value={values.phone}
                                                onChange={(phoneNumber, country) => { handlePhone(country, phoneNumber) }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Enter your email</span>
                                        </div>
                                        <div className='custom_col'>
                                            <input
                                                type='email'
                                                name='email'
                                                id='email'
                                                placeholder='e.g david@gmail.com'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Enter your address</span>
                                        </div>
                                        <div className='custom_col'>
                                            <ReactSelect
                                                name='address'
                                                placeholder='Search address'
                                                isSearchable={true}
                                                className='react-select-custom'
                                                isClearable={true}
                                                value={values.address}
                                                onInputChange={e => {
                                                    if (e) setPossiblePlacesFunc(e);
                                                }}
                                                onChange={e => {
                                                    setFieldValue('address', e)
                                                }}
                                                onBlur={e => {
                                                    handleBlur(e);
                                                }}
                                                options={addressList}
                                            />
                                            {/* <input
                                                style={{ width: '100%' }}
                                                type='text'
                                                name='address'
                                                id='address'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.address}
                                            /> */}
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='submit-btn'>
                                            <button type='button'
                                                onClick={() => customProps.handleSendOTP(isMobile)}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }

                            {customProps.otpStatus &&
                                <div className='custom_row'>
                                    <div className='custom_col' style={{ paddingLeft: 10, paddingBottom: 10 }}>
                                        <span className='input-label'>Verify OTP</span>
                                    </div>
                                    <div className='custom_col'>
                                        <OtpInput
                                            value={isOTP}
                                            onChange={handleChangeOTP}
                                            numInputs={6}
                                            separator={<span>-</span>}
                                            shouldAutoFocus
                                            inputStyle={classes.otpInputStyle}
                                            hasErrored={true}
                                            isInputNum={true}
                                        />
                                    </div>
                                    <div className='submit-btn'>
                                        <button type="submit">
                                            Verify
                                        </button>
                                    </div>
                                    <div className='resend_otp'>
                                        <button type='button' className='link-btn' onClick={() => customProps.handleSendOTP(isMobile)}>
                                            Resend OTP
                                        </button>
                                    </div>
                                </div>
                            }

                            <div className='custom_row'>
                                <div className='custom_col'>
                                    <div className='divider_text'>
                                        <span></span>
                                        or
                                        <span></span>
                                    </div>
                                </div>
                                <div className='custom_col'>
                                    <div className='google-signIn'>
                                        {/* <button>
                                            <GoogleIcon /> Sign Up With Google
                                        </button> */}
                                        <GoogleLogin
                                            clientId="667514731244-a8feu1a6na78ndjd4rk6o19f7f2jkead.apps.googleusercontent.com"
                                            render={renderProps => (
                                                <button
                                                    onClick={renderProps.onClick}
                                                    disabled={renderProps.disabled}>
                                                    <GoogleIcon /> Sign Up With Google
                                                </button>
                                            )}
                                            // buttonText="Sign In With Google"
                                            onSuccess={props.handleGoogleSignIn}
                                            onFailure={props.handleGoogleSignIn}
                                            cookiePolicy={'single_host_origin'}
                                            isSignedIn={false}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='custom_row'>
                                <div className='custom_col'>
                                    <span className='bottom_link'>Already have an account? <p onClick={() => customProps.handleSignUp()}>Sign In</p></span>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default EditProfile;