import React, { } from 'react'
import './style.css'
import { connect } from 'react-redux';
import { streamActions } from '../../redux/liveStream/stream'
import { Oval } from 'react-loader-spinner';

export default connect(
    state => ({
        limit: state.stream.stream.limit,
        count: state.stream.stream.count,
        page: state.stream.stream.page,
        listView: state.stream.stream.listView,
        isNext: state.stream.stream.isNext,
        isViewAllLoader: state.stream.stream.isViewAllLoader,
    }),
    dispatch => ({
        _loadViewItems: payload => dispatch(streamActions.loadViewItems(payload)),
    }),
)(function Pagination(props) {

    const loadMoreData = () => {
        try {
            let x = { ...props };
            if (props.isNext) {
                x.limit += 12
            }

            let obj = {}

            if (props.isCategory === 'all') {
                if (props.shopId !== null || props.shopId !== undefined || props.shopId !== "") {
                    obj = {
                        limit: x.limit,
                        shopId: props.shopId,
                        status: props.status
                    }
                } else {
                    obj = {
                        limit: x.limit,
                        status: props.status
                    }
                }
            }
            else {
                if (props.shopId !== null || props.shopId !== undefined || props.shopId !== "") {
                    obj = {
                        limit: x.limit,
                        shopId: props.shopId,
                        status: props.status,
                        category: props.isCategory,
                    }
                } else {
                    obj = {
                        limit: x.limit,
                        status: props.status,
                        category: props.isCategory,
                    }
                }
            }
            props._loadViewItems(obj);
        }
        catch (err) {
            console.log("catch error", err)
        }
    }

    return (
        <div className={props.isTheme ? 'pagination-main-light pagination-main' : 'pagination-main'} style={{ position: 'relative' }}>
            <Oval
                arialLabel="loading-indicator"
                height={40}
                width={40}
                color='green'
                visible={props.isViewAllLoader}
                wrapperStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    background: 'transparent',
                    zIndex: 99,
                    marginBottom: 20,
                }}
            />
            {props.isNext ?
                <button onClick={() => loadMoreData()}>Load More</button>
                : null
            }
        </div>
    )
});