import { all } from 'redux-saga/effects';
import { AuthSagas } from './redux/register/register';
import { LoginSagas } from './redux/login/login';
import { streamSagas } from './redux/liveStream/stream'
import { shopSagas } from './redux/shop/shop'

export default function* rootSaga() {
  yield all([
    ...AuthSagas,
    ...LoginSagas,
    ...streamSagas,
    ...shopSagas,
  ]);
}
