import React, { useEffect } from 'react'
import './style.css'
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux';
import ShopCategory from './shopCategories'
import { shopActions } from '../../redux/shop/shop'
import ShopList from './list'
import { Oval } from 'react-loader-spinner';

const useStyles = createUseStyles({
    main: {
        background: '#111111',
        color: '#ffffff',
        paddingTop: '4rem',
        paddingBottom: '4rem',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
        paddingTop: '4rem',
        paddingBottom: '4rem',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default connect(
    state => ({
        categoryList: state.shop.shop.categoryList,
        shopList: state.shop.shop.shopList,
        categoryLoader: state.shop.shop.categoryLoader,
        shopLoader: state.shop.shop.shopLoader,
        loggedUser: state.login.login.loggedUser,
    }),
    dispatch => ({
        _categories: payload => dispatch(shopActions.shopCategory(payload)),
        _listAllShop: payload => dispatch(shopActions.listAllShop(payload)),
    }),
)(function Shop(props) {

    const classes = useStyles();

    useEffect(() => {
        let a = { ...props }
        a.limit = 12;

        props._listAllShop({ a })
        props._categories({ limit: 20, skip: 0 });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const filterCategory = (category) => {
        let a = { ...props }
        a.limit = 12;

        if (category === 'all') {
            props._listAllShop({ a })
        } else {
            props._listAllShop({ category: category, a })
        }
    }

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className={classes.wrapper}>
                {props.categoryLoader ?
                    <Oval
                        arialLabel="loading-indicator"
                        height={40}
                        width={40}
                        color='green'
                        visible={true}
                        wrapperStyle={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            background: 'transparent',
                            zIndex: 99,
                            margin: '1rem 0',
                        }}
                    />
                    :
                    <ShopCategory
                        filterCategory={filterCategory}
                        isTheme={props.isTheme}
                        categories={props.categoryList}
                    />
                }

                <ShopList
                    isTheme={props.isTheme}
                    list={props.shopList}
                    {...props}
                />

            </div>
        </div>
    );
});