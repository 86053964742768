import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner"
import ListView from "../components/ViewAll"
import Footer from "../components/Footer";
import { ArrowUp } from "../sharedUtils/globalIcons"
import "../App.css"

export default function Home(props) {

    const [isTheme, setIsTheme] = useState(
        localStorage.getItem('theme') === "false" ? false : true
    );

    useEffect(() => {
        localStorage.setItem('theme', isTheme)
    }, [isTheme])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    const toggleTheme = () => {
        setIsTheme(prev => (!prev))
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])
    
    return (
        <div className="home-main">
            <Navbar isTheme={isTheme} toggleTheme={toggleTheme} searchFor="allStream" streamStatus={props.location?.state?.status} />
            <Banner {...props} />
            <ListView isTheme={isTheme} {...props} />
            <div style={{ position: 'relative' }}>
                <Footer isTheme={isTheme} />
                <div className="scrollTop" onClick={scrollTop}>
                    <span><ArrowUp /></span>
                </div>
            </div>
        </div>
    );
};