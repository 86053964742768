import { initializeApp } from 'firebase/app';
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDq6gmA9uVs46LgTWf43stHT2WECS8YEzs",
    authDomain: "shoprun-330213.firebaseapp.com",
    projectId: "shoprun-330213",
    storageBucket: "shoprun-330213.appspot.com",
    messagingSenderId: "667514731244",
    appId: "1:667514731244:web:b29d8caf31f0f7838e43c9",
    measurementId: "G-RF9LSRJTRD"
};

const app = initializeApp(firebaseConfig);

export const authentication = getAuth(app);
