import React, { useEffect } from "react";
import LiveVideo from "../components/Video"
import LiveChat from "../components/Chat"
import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux';
import { streamActions } from "../redux/liveStream/stream";
import { Oval } from 'react-loader-spinner';

const useStyles = createUseStyles({
    liveStreamVid: {
        background: '#0B0909',
        padding: '1.75rem 4rem',
        width: '100%',
        height: '100vh',
        boxSizing: 'border-box',
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'space-around',
        gap: '50px',
    },
    '@media screen and (max-width: 480px)': {
        liveStreamVid: {
            padding: '1rem',
            display: 'block',
            height: '100%',
        },
    },
    '@media screen and (max-width: 767px)': {
        liveStreamVid: {
            padding: '1rem',
        },
    },
    '@media (min-width:768px) and (max-width: 1024px)': {
        liveStreamVid: {
            padding: '1rem',
            gap: '20px'
        },
    },
});

export default connect(
    state => ({
        loggedUser: state.login.login.loggedUser,
        isGuestUser: state.login.login.isGuestUser,
        likeResonse: state.stream.stream.likeResonse,
        singleStreamData: state.stream.stream.singleStreamData,
        streamLoader: state.stream.stream.streamLoader,
        likeResponse: state.stream.stream.likeResponse,
        likeLoader: state.stream.stream.likeLoader,
    }),
    dispatch => ({
        _likeLiveStream: payload => dispatch(streamActions.likeLiveStream(payload)),
        _liveStreamData: payload => dispatch(streamActions.liveStreamData(payload)),
    }),
)(function LivestreamApp(props) {

    const slug = props.match?.params?.slug;
    const streamName = props.match?.params?.streamName;

    useEffect(() => {
        if (slug) {
            props._liveStreamData({
                slug: slug,
            })
        }
    }, [slug])

    // useEffect(() => {
    //     if (props.likeResponse && id) {
    //         props._liveStreamData({
    //             _id: id,
    //             streamName: streamName,
    //         })
    //     }
    // }, [props.likeResponse])


    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    const classes = useStyles();

    if (props.streamLoader) {
        return (
            <Oval
                arialLabel="loading-indicator"
                height={30}
                width={30}
                color='green'
                wrapperStyle={{
                    justifyContent: 'center',
                    position: 'absolute',
                    alignItems: 'center',
                    left: 0,
                    right: 0,
                    top: 0,
                    height: '100%',
                    background: 'rgb(0 0 0)',
                    zIndex: 99,
                }}
            />
        )
    }

    // if (props.likeLoader) {
    //     return (
    //         <Oval
    //             arialLabel="loading-indicator"
    //             height={30}
    //             width={30}
    //             color='green'
    //             wrapperStyle={{
    //                 justifyContent: 'center',
    //                 position: 'absolute',
    //                 alignItems: 'center',
    //                 left: 0,
    //                 right: 0,
    //                 top: 0,
    //                 height: '100%',
    //                 background: 'rgb(0 0 0)',
    //                 zIndex: 99,
    //             }}
    //         />
    //     )
    // }

    if (props.singleStreamData === "" || props.singleStreamData === undefined) {
        return null;
    }

    return (
        <div className={classes.liveStreamVid}>
            <LiveVideo  {...props} />
            <LiveChat {...props} />
        </div>
    );
});