import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = createUseStyles({
    tabBtn: {
        width: '100%',
        height: '71px',
        borderRadius: 3,
        boxShadow: '0px 6px 25px rgba(7, 6, 6, 0.08)',
        background: '#ffffff',
        fontWeight: 600,
        fontSize: '1rem',
        cursor: 'pointer',
        border: 0,
        fontFamily: '"Poppins", sans-serif',
        color: '#000000'
    },
    activeTabBtn: {
        width: '100%',
        height: '71px',
        borderRadius: 3,
        boxShadow: '0px 6px 25px rgba(7, 6, 6, 0.08)',
        fontWeight: 600,
        fontSize: '1rem',
        cursor: 'pointer',
        border: 0,
        fontFamily: '"Poppins", sans-serif',
        background: 'linear-gradient(147.67deg, #43B028 24.24%, #FBCE2E 145.04%)',
        color: '#ffffff'
    }
});

const Categories = (props) => {
    const classes = useStyles({ ...props });

    const [isActive, setIsActive] = useState('all');

    useEffect(() => {
        if (props.isCategory)
            setIsActive(props.isCategory);
    }, [props.isCategory]);

    const LeftNavigation = ({ onClick }) => {
        return (
            <div className="prevArrow" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 25, display: 'block' }} viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144" /></svg>
            </div>
        )
    }

    const RightNavigation = ({ onClick }) => {
        return (
            <div className="nextArrow" onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 25, display: 'block' }} viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144" /></svg>
            </div>
        )
    }

    var SlideSettings = {
        arrows: true,
        speed: 500,
        infinite: props.categories?.length > 9 ? true : false,
        swipeToSlide: true,
        accessibility: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        prevArrow: <LeftNavigation onClick />,
        nextArrow: <RightNavigation onClick />,
        draggable: true,
        responsive: [
            {
                breakpoint: 1490,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: false,
                }
            }
        ]
    };

    const filterItem = (category) => {
        if (category === 'all') {
            props.filterCategory('all');
            setIsActive('all');
        }
        else {
            props.filterCategory(category._id);
            setIsActive(category?._id);
        }
    }

    return (
        <div>
            {props.categories !== null && props.categories !== undefined &&
                <div className={props.isTheme ? 'lightmenuTabs menuTabs' : 'menuTabs'}>
                    <Slider {...SlideSettings}>
                        <button className={isActive === 'all' ? classes.activeTabBtn : classes.tabBtn} onClick={() => filterItem('all')}>All</button>
                        {props.categories && props.categories.map((e, index) => (
                            <button className={isActive === e._id ? classes.activeTabBtn : classes.tabBtn} onClick={() => filterItem(e)}>{e.name}</button>
                        ))}
                    </Slider>
                </div>
            }
        </div>
    );
};

export default Categories;