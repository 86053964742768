import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { connect } from 'react-redux';
import { MapMarkerIcon } from '../../sharedUtils/globalIcons';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shopActions } from '../../redux/shop/shop';
import { Oval } from 'react-loader-spinner';
import DefaultShopImage from '../../assets/default-shop-image.png';

const useStyles = createUseStyles({
    Title: {
        color: '#ffffff',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        marginBottom: '2rem',
        textAlign: 'center',
    },
    LightTitle: {
        color: '#111111',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        textAlign: 'center',
    },
    main: {
        background: '#111111',
        color: '#ffffff',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default connect(
    state => ({
        limit: state.shop.shop.limit,
        count: state.shop.shop.count,
        page: state.shop.shop.page,
        isNext: state.shop.shop.isNext,
        loader: state.shop.shop.loader,
        shopLoader: state.shop.shop.shopLoader,
    }),
    dispatch => ({
        _loadViewItems: payload => dispatch(shopActions.loadViewItems(payload)),
    }),
)(function ListView(props) {

    const classes = useStyles();

    const [hasMore, setIsHasMore] = useState(true);

    const fetchMoreData = () => {

        if (!props?.isNext) {
            setIsHasMore(false);
        }
        // a fake async api call like which sends
        // 20 more records in 1.5 secs
        setTimeout(() => {
            try {
                let x = { ...props };
                if (props.isNext) {
                    x.limit += 12
                }

                let obj = {}

                if (props.isCategory === 'all')
                    obj = {
                        limit: x.limit,
                    }
                else
                    obj = {
                        limit: x.limit,
                        category: props.isCategory,
                    }
                props._loadViewItems(obj);
            }
            catch (err) {
                console.log("catch error", err)
            }
        }, 1500);
    };

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className='shop-main'>
                {props.shopLoader ?
                    <Oval
                        arialLabel="loading-indicator"
                        height={40}
                        width={40}
                        color='green'
                        visible={props.shopLoader}
                        wrapperStyle={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                            background: 'transparent',
                            zIndex: 99,
                            marginBottom: 20,
                        }}
                    />
                    :
                    <div className='shop-list-container'>
                        <InfiniteScroll
                            dataLength={props.list && props.list !== undefined ? props.list && props.list?.length : ''}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            loader={
                                <Oval
                                    arialLabel="loading-indicator"
                                    height={40}
                                    width={40}
                                    color='green'
                                    visible={true}
                                    wrapperStyle={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: '100%',
                                        width: '100%',
                                        background: 'transparent',
                                        zIndex: 99,
                                        marginBottom: 20,
                                    }}
                                />
                            }
                            style={{ display: 'flex', flexWrap: 'wrap' }}
                        >
                            {props.list.length > 0 ?
                                props.list && props.list.map((e) => (
                                    <div className='shop_list'>
                                        <Link
                                            to={{
                                                pathname: `/shop/${e?.slug}`,
                                            }}
                                        >
                                            <div className={props.isTheme ? 'light-shop-box shop-box' : 'shop-box'}>
                                                <div className='shop-img'>
                                                    <img src={e.image !== "null" && e.image || DefaultShopImage} alt={e.name} />
                                                </div>
                                                <div className='shop-details'>
                                                    <h4>{e.name || 'No Title'}</h4>
                                                    <p className='address'>
                                                        <MapMarkerIcon />
                                                        <span>{e.location?.address || ''}</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))
                                :
                                <div style={{ margin: '0 auto' }}>No Data Found</div>
                            }
                        </InfiniteScroll>
                    </div>
                }
            </div>
        </div >
    );
});