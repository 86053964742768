import produce from 'immer';
import { put, select, takeLatest } from 'redux-saga/effects';
import backendApi from './model/api';

// constants
export const shopConstants = {
    SHOP_CATEGORY_REQ: 'SHOP_CATEGORY_REQ',
    SHOP_CATEGORY_REQ_SUCCESS: 'SHOP_CATEGORY_REQ_SUCCESS',
    SHOP_CATEGORY_REQ_FAILURE: 'SHOP_CATEGORY_REQ_FAILURE',

    SHOP_LIST_REQ: 'SHOP_LIST_REQ',
    SHOP_LIST_REQ_SUCCESS: 'SHOP_LIST_REQ_SUCCESS',
    SHOP_LIST_REQ_FAILURE: 'SHOP_LIST_REQ_FAILURE',

    SHOP_LOAD_MORE_DATA: 'SHOP_LOAD_MORE_DATA',
    SHOP_LOAD_MORE_DATA_SUCCESS: 'SHOP_LOAD_MORE_DATA_SUCCESS',
    SHOP_LOAD_MORE_DATA_FAILURE: 'SHOP_LOAD_MORE_DATA_FAILURE',

    GET_SHOP_DETAILS: 'GET_SHOP_DETAILS',
    GET_SHOP_DETAILS_SUCCESS: 'GET_SHOP_DETAILS_SUCCESS',
    GET_SHOP_DETAILS_FAILURE: 'GET_SHOP_DETAILS_FAILURE',
};

export const shopActions = {};
// actions

shopActions.shopCategory = payload => ({
    type: shopConstants.SHOP_CATEGORY_REQ,
    payload,
});

shopActions.listAllShop = payload => ({
    type: shopConstants.SHOP_LIST_REQ,
    payload,
})

shopActions.loadViewItems = payload => ({
    type: shopConstants.SHOP_LOAD_MORE_DATA,
    payload,
})

shopActions.getShopDetails = payload => ({
    type: shopConstants.GET_SHOP_DETAILS,
    payload,
})



// The initial state of the App
export const initialState = {
    shopList: [],
    categoryList: [],
    limit: 10,
    sort: 'name',
    sortType: 'asc',
    page: 1,
    count: 0,
    categoryLoader: false,
    isCategory: '',
    loader: false,
    shopDetail: [],
    detailLoader: false,
    shopLoader: false,
};

// reducers
const appReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case shopConstants.SHOP_CATEGORY_REQ:
                draft.categoryLoader = true;
                break;
            case shopConstants.SHOP_CATEGORY_REQ_SUCCESS:
                draft.categoryList = action.payload.data;
                draft.categoryLoader = false;
                break;
            case shopConstants.SHOP_CATEGORY_REQ_FAILURE:
                draft.categoryList = action.payload.data;
                draft.categoryLoader = false;
                break;
            case shopConstants.SHOP_LIST_REQ:
                draft.limit = action.payload.a?.limit;
                draft.isCategory = action.payload.category;
                draft.shopLoader = true;
                break;
            case shopConstants.SHOP_LIST_REQ_SUCCESS:
                draft.shopList = action.payload.data;
                draft.isNext = action.payload.isNext;
                draft.loader = false;
                draft.shopLoader = false;
                break;
            case shopConstants.SHOP_LIST_REQ_FAILURE:
                draft.shopList = action.payload.data;
                draft.isNext = action.payload.isNext;
                draft.loader = false;
                draft.shopLoader = false;
                break;
            case shopConstants.SHOP_LOAD_MORE_DATA:
                draft.limit = action.payload.limit;
                draft.loader = true;
                break;
            case shopConstants.GET_SHOP_DETAILS:
                draft.detailLoader = true;
                break;
            case shopConstants.GET_SHOP_DETAILS_SUCCESS:
                draft.shopDetail = action.payload.data;
                draft.detailLoader = false;
                break;
            case shopConstants.GET_SHOP_DETAILS_FAILURE:
                draft.shopDetail = action.payload.data;
                draft.detailLoader = false;
                break;
            default:
                break;
        }
    });
export default appReducer;

export const getAllState = state => state.shop;

function* shopCategoryReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.shopCategoryAPI(props.payload);

        if (res.success)
            yield put({ type: shopConstants.SHOP_CATEGORY_REQ_SUCCESS, payload: res });
        else
            yield put({ type: shopConstants.SHOP_CATEGORY_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: shopConstants.SHOP_CATEGORY_REQ_FAILURE, payload: false });
    }
}

function* shopListReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.shopListAPI(i, props.payload);

        if (res.success)
            yield put({ type: shopConstants.SHOP_LIST_REQ_SUCCESS, payload: res });
        else
            yield put({ type: shopConstants.SHOP_LIST_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: shopConstants.SHOP_LIST_REQ_FAILURE, payload: false });
    }
}

function* getShopDetailReq(props) {
    try {
        const i = yield select(getAllState);
        const res = yield backendApi.getShopDetailsAPI(props.payload);

        if (res.success)
            yield put({ type: shopConstants.GET_SHOP_DETAILS_SUCCESS, payload: res });
        else
            yield put({ type: shopConstants.GET_SHOP_DETAILS_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: shopConstants.GET_SHOP_DETAILS_FAILURE, payload: false });
    }
}

export const shopSagas = [
    takeLatest(shopConstants.SHOP_CATEGORY_REQ, shopCategoryReq),
    takeLatest(shopConstants.SHOP_LIST_REQ, shopListReq),
    takeLatest(shopConstants.SHOP_LOAD_MORE_DATA, shopListReq),
    takeLatest(shopConstants.GET_SHOP_DETAILS, getShopDetailReq)
];