import backend from '../../../sharedUtils/backend';

export default new (class authAPI extends backend {
    constructor() {
        super();
    }

    queryBuilder(att) {
        let queryString = `?sort=${att.sort}`;
        queryString += '&skip=' + (att.page - 1) * att.limit;
        queryString += '&limit=' + att.limit;

        return queryString;
    }

    liveStreamsAPI(query, data) {
        let str = query.stream ? this.queryBuilder(query.stream) : '';
        if (data.status) {
            data.status.map((e) => {
                str += '&status=' + e;
            })
        }
        if (data.category && data.category !== undefined)
            str += '&category=' + data.category
        if (data.shopId && data.shopId !== undefined)
            str += '&shopId=' + data.shopId
        if (data.streamer && data.streamer !== undefined)
            str += '&streamer=' + data.streamer
        if (data.search && data.search !== undefined)
            str += '&search=' + data.search
        if (data.sortType && data.sortType !== undefined)
            str += '&sortType=' + data.sortType
        if (data.sortTypeforPast && data.sortTypeforPast !== undefined)
            str += '&sortType=' + data.sortTypeforPast
        if (data.sortTypeforUpcoming && data.sortTypeforUpcoming !== undefined)
            str += '&sortType=' + data.sortTypeforUpcoming

        return this.post('live-stream/list' + str);
    }

    categoryListApi(data) {
        let str = ''
        str += '?limit=' + data.limit
        str += '&skip=' + data.skip

        return this.post('category/list' + str);
    }

    listAllVideoAPI(query, data) {

        try {

            let str = query.stream ? this.queryBuilder(query.stream) : '';

            if (data.status) {
                data.status.map((e) => {
                    str += '&status=' + e;
                })
            }

            if (data.category)
                str += '&category=' + data.category

            if (data.sortType)
                str += '&sortType=' + data.sortType

            if (data.shopId)
                str += '&shopId=' + data.shopId

            if (data.search && data.search !== undefined)
                str += '&search=' + data.search

            return this.post('live-stream/list' + str);
        } catch (err) {
            console.log('catch new errr', err)
        }
    }

    likeLiveStreamAPI(data) {
        return this.post(`live-stream/like/${data._id}?like=${data.like}`)
    }

    streamDetailApi(data) {
        return this.get(`live-stream/s/${data.slug}`)
    }

})();
