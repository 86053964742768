import axios from 'axios';

export default class backend {

  constructor() {
    if (process.env.NODE_ENV === 'development') {
      this.baseURL = 'http://localhost:8087/api/';
    } else {
      this.baseURL = '/api/';
    }
  }

  get(url) {
    const that = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'get',
        url: that.baseURL + url,
        withCredentials: true,
      })
        .then(response => {
          if (response.data.status === 1001) {
            // history.push('/auth/login');
            reject(new Error(response.data));
          } else if (response.data.status === 1004) {
            reject(new Error(response.data));
          } else if (response.data.err || response.data.errmsg) {
            reject(response);
          } else {
            resolve(response.data);
          }
        })
        .catch(response => {
          reject(response);
        });
    });
  }

  post(url, data) {
    const that = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'post',
        url: that.baseURL + url,
        withCredentials: true,
        data: data,
      })
        .then(response => {
          if (response.data.status === 1001) {
            // history.push('/login');
            reject(new Error(response.data));
          } else if (response.data.err || response.data.errmsg) {
            reject(response);
          } else {
            resolve(response.data);
          }
        })
        .catch(resp => {
          reject(resp);
        });
    });
  }

  put(url, data) {
    const that = this;
    return new Promise((resolve, reject) => {
      axios({
        method: 'put',
        url: that.baseURL + url,
        withCredentials: true,
        data: data,
      })
        .then(response => {
          if (response.data.status === 1001) {
            // history.push('/login');
            reject(new Error(response.data));
          } else if (response.data.err || response.data.errmsg) {
            reject(response);
          } else {
            resolve(response.data);
          }
        })
        .catch(resp => {
          reject(resp);
        });
    });
  }
}
