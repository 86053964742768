import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss'
import Category from './categories'
import LiveUpcoming from './live-upcoming';
import Past from './past';
import Promoted from './promoted'
import { connect } from 'react-redux';
import { streamActions } from '../../redux/liveStream/stream'
import { Oval } from 'react-loader-spinner';

const useStyles = createUseStyles({
    main: {
        background: '#111111',
        color: '#ffffff',
        paddingTop: '4rem',
        paddingBottom: '4rem',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
        paddingTop: '4rem',
        paddingBottom: '4rem',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default connect(
    state => ({
        liveStream: state.stream.stream.liveStream,
        liveAndUpcoming: state.stream.stream.liveAndUpcoming,
        pastStream: state.stream.stream.pastStream,
        categories: state.stream.stream.categories,
        categoryLoader: state.stream.stream.categoryLoader,
        isCategory: state.stream.stream.isCategory,
        logged: state.login.login.logged,
    }),
    dispatch => ({
        _promotedVideos: payload => dispatch(streamActions.promotedVideos(payload)),
        _liveAndUpcoming: payload => dispatch(streamActions.liveAndUpcoming(payload)),
        _pastStreams: payload => dispatch(streamActions.pastStreams(payload)),
        _categories: payload => dispatch(streamActions.categories(payload)),
    }),
)(function Navbar(props) {
    const classes = useStyles();

    useEffect(() => {
        if (props.shopId !== undefined && props.showingFrom === 'Shop') {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc', category: props.isCategory, shopId: props.shopId })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc', category: props.isCategory, shopId: props.shopId })
            props._pastStreams({ status: ['finished'], sortType: 'desc', category: props.isCategory, shopId: props.shopId })
        } else if (props.InfluencerId !== undefined && props.showingFrom === 'Influencer') {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc', category: props.isCategory, streamer: props.InfluencerId })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc', category: props.isCategory, streamer: props.InfluencerId })
            props._pastStreams({ status: ['finished'], sortType: 'desc', category: props.isCategory, streamer: props.InfluencerId })
        }
        else {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc', category: props.isCategory })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc', category: props.isCategory })
            props._pastStreams({ status: ['finished'], sortType: 'desc', category: props.isCategory })
            props._categories({ limit: 20, skip: 0 })
        }
    }, [props.logged])

    const filterCategory = (category) => {
        if (category === 'all') {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc' })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc' })
            props._pastStreams({ status: ['finished'], sortType: 'desc' })
        } else {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc', category: category })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc', category: category })
            props._pastStreams({ status: ['finished'], sortType: 'desc', category: category })
        }
    }

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className={classes.wrapper}>
                {props.showingFrom === 'Shop' || props.showingFrom === 'Influencer' ? null :
                    props.categoryLoader ?
                        <Oval
                            arialLabel="loading-indicator"
                            height={40}
                            width={40}
                            color='green'
                            visible={true}
                            wrapperStyle={{
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100%',
                                background: 'transparent',
                                zIndex: 99,
                            }}
                        />
                        :
                        <Category
                            isCategory={props.isCategory}
                            isTheme={props.isTheme}
                            categories={props.categories.data}
                            filterCategory={filterCategory}
                        />
                }
                <Promoted isTheme={props.isTheme} allStreams={props.liveStream} />
                <LiveUpcoming isTheme={props.isTheme} liveAndUpcoming={props.liveAndUpcoming} />
                <Past isTheme={props.isTheme} pastStream={props.pastStream} showingFrom={props.showingFrom} shopId={props.shopId} />
            </div>
        </div>
    );
});