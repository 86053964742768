import React, { useState } from 'react'
import './index.css'
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import OtpInput from 'react-otp-input';
import { createUseStyles } from 'react-jss';
import { config } from '../../service/aws'
import { uploadFile } from 'react-s3';
import ReactSelect from 'react-select';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import DefaultAvatar from '../../assets/user.png';

window.Buffer = window.Buffer || require("buffer").Buffer;


const useStyles = createUseStyles({
    otpInputStyle: {
        width: '35px !important',
        height: '35px !important',
        margin: '0 0.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
        background: '#eff0f4',
        border: '1px solid #53b029',
        color: '#53b029',
        borderRadius: '3px',
        padding: '0 !important'
    },
});

const SignUp = (props) => {

    const classes = useStyles();
    const customProps = props;

    const [isMobile, setIsMobile] = useState();
    const [isOTP, setIsOTP] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1);
    const [uploadImg, setUploadImg] = useState('');

    const handlePhone = (country, phoneNumber) => {
        const phone = {
            countryCode: '+' + country.dialCode,
            mobile: phoneNumber.replace(country.dialCode, ''),
        }
        setIsMobile(phone);
    }

    const handleChangeOTP = (otp) => {
        setIsOTP(otp);
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const uploadAvatar = async (e) => {
        e.preventDefault();
        let file = e?.target?.files[0];
        setUploadImg(file);
        console.log("upload avatar", e?.target?.files[0]);
        // props.handleLoading(true);
        // uploadFile(file, config)
        //     .then(data => {
        //         if (data.location)
        //             props._editProfile({ image: data.location });
        //     }).catch(err => console.log('err -------', err))
    }

    const getSrcFromFile = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsDataURL(file.originFileObj);
            reader.onload = () => resolve(reader.result);
        });
    };

    let validImg = ''
    validImg = /\.jpe?g$/i.test(props.loggedUser?.data?.image)

    const [fileList, setFileList] = useState([
        {
            uid: '-1',
            url: validImg ? props.loggedUser?.data?.image : DefaultAvatar,
        },
    ]);

    const onPreview = async (file) => {
        const src = file.url || (await getSrcFromFile(file));
        const imgWindow = window.open(src);

        if (imgWindow) {
            const image = new Image();
            image.src = src;
            imgWindow.document.write(image.outerHTML);
        } else {
            window.location.href = src;
        }
    };

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
        const file = newFileList.reduce((a, b) => Object.assign(a, b), {});

        if (newFileList.length > 0) {
            props.handleLoading(true);
            uploadFile(file?.originFileObj, config)
                .then(data => {
                    if (data.location)
                        props._editProfile({ image: data.location });
                }).catch(err => console.log('err -------', err))
        }
    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <>
            <div className='login_form'>
                <Formik
                    initialValues={{
                        firstName: props.loggedUser?.data?.fName ? props.loggedUser?.data?.fName : "",
                        phone: props.loggedUser?.data?.countryCode + (props.loggedUser?.data?.mobile ? props.loggedUser?.data?.mobile : ""),
                        email: props.loggedUser?.data?.email ? props.loggedUser?.data?.email : "",
                        gender: props.loggedUser?.data?.gender ?
                            {
                                label: props.loggedUser?.data?.gender,
                                value: props.loggedUser?.data?.gender,
                            }
                            : null,
                    }}
                    onSubmit={(values) => {
                        props.handleLoading(true);
                        const obj = {}
                        const mobile = {
                            countryCode: isMobile?.countryCode,
                            mobile: isMobile?.mobile,
                        }

                        if (values.firstName) obj.fName = values.firstName
                        if (values.email) obj.email = values.email
                        if (values.phone) obj.mobile = props.loggedUser?.data?.mobile
                        if (values.phone) obj.countryCode = props.loggedUser?.data?.countryCode
                        if (values.gender) obj.gender = values?.gender.value

                        if (isMobile !== undefined && (isMobile.countryCode + isMobile.mobile !== props.loggedUser?.data?.mobile)) {
                            customProps.handleMobileOTP(isMobile);
                        }
                        else {
                            props._editProfile(obj);
                        }
                    }}
                >
                    {({
                        values,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                    }) => (
                        <form onSubmit={handleSubmit}>
                            {!customProps.otpStatus &&
                                <>
                                    <div className='custom_row'>
                                        <div className='custom_col' style={{ margin: '0 auto' }}>
                                            {/* <div class="image-upload">
                                                <label for="file-input" className='profile_picture'>
                                                    <img src={props.loggedUser?.data?.image} />
                                                    <span className='file-upload-icon'><CameraIcon /></span>
                                                </label>

                                                <input
                                                    id="file-input"
                                                    name="profile_pic"
                                                    type="file"
                                                    onChange={uploadAvatar}
                                                />
                                            </div> */}
                                            <div className='crop-img'>
                                                <ImgCrop grid rotate>
                                                    <Upload
                                                        action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                        listType="picture-card"
                                                        fileList={fileList}
                                                        onChange={onChange}
                                                        onPreview={onPreview}
                                                        customRequest={dummyRequest}
                                                    >
                                                        {fileList.length < 1 && '+ Upload'}
                                                    </Upload>
                                                </ImgCrop>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>First and last name</span>
                                        </div>
                                        <div className='custom_col'>
                                            <input
                                                type='text'
                                                name='firstName'
                                                id='firstName'
                                                placeholder='David Beckham'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Gender</span>
                                        </div>
                                        <div className='custom_col'>
                                            {/* <span className='edit_address'>{props.loggedUser?.data?.gender}</span> */}
                                            <ReactSelect
                                                name='gender'
                                                placeholder='Select Gender'
                                                className='react-select-custom'
                                                isClearable={true}
                                                value={values.gender}
                                                onChange={e => {
                                                    setFieldValue('gender', e)
                                                }}
                                                onBlur={e => {
                                                    handleBlur(e);
                                                }}
                                                options={[
                                                    {
                                                        value: 'Male',
                                                        label: 'Male',
                                                    },
                                                    {
                                                        value: 'Female',
                                                        label: 'Female',
                                                    }
                                                ]}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Mobile number</span>
                                        </div>
                                        <div className='custom_col'>
                                            <PhoneInput
                                                inputProps={{
                                                    name: 'phone',
                                                    required: true,
                                                    autoFocus: true,
                                                }}
                                                country={'au'}
                                                name='phone'
                                                id='phone'
                                                value={values.phone}
                                                onChange={(phoneNumber, country) => { handlePhone(country, phoneNumber) }}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                    </div>

                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Email</span>
                                        </div>
                                        <div className='custom_col'>
                                            <input
                                                type='email'
                                                name='email'
                                                id='email'
                                                placeholder='e.g david@gmail.com'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                readOnly={props.loggedUser?.data?.googleId ? true : false}
                                            />
                                        </div>
                                    </div>

                                    {/* 
                                    <div className='custom_row'>
                                        <div className='custom_col'>
                                            <span className='input-label'>Address</span>
                                        </div>
                                        <div className='custom_col'>
                                            <span className='edit_address'>{props.loggedUser?.data?.locations.map((e) => e.selected && e.address)}</span>
                                            <ReactSelect
                                                name='address'
                                                placeholder='Search address'
                                                isSearchable={true}
                                                className='react-select-custom'
                                                isClearable={true}
                                                value={values.address}
                                                onInputChange={e => {
                                                    if (e) setPossiblePlacesFunc(e);
                                                }}
                                                onChange={e => {
                                                    setFieldValue('address', e)
                                                }}
                                                onBlur={e => {
                                                    handleBlur(e);
                                                }}
                                                options={addressList}
                                            />
                                        </div>
                                    </div> 
                                    */}

                                    <div className='custom_row'>
                                        <div className='submit-btn'>
                                            <button type='submit'>
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </>
                            }

                            {customProps.otpStatus &&
                                <div className='custom_row'>
                                    <div className='custom_col' style={{ paddingLeft: 10, paddingBottom: 10 }}>
                                        <span className='input-label'>Verify OTP</span>
                                    </div>
                                    <div className='custom_col'>
                                        <OtpInput
                                            value={isOTP}
                                            onChange={handleChangeOTP}
                                            numInputs={6}
                                            separator={<span>-</span>}
                                            shouldAutoFocus
                                            inputStyle={classes.otpInputStyle}
                                            hasErrored={true}
                                            isInputNum={true}
                                        />
                                    </div>
                                    <div className='submit-btn'>
                                        <button type="button" onClick={() => customProps.handleVerifyMobile(isMobile, isOTP)}>
                                            Verify
                                        </button>
                                    </div>
                                    <div className='resend_otp'>
                                        <button type='button' className='link-btn' onClick={() => customProps.handleLoginSendOTP(isMobile)}>
                                            Resend OTP
                                        </button>
                                    </div>
                                </div>
                            }

                            <div className='custom_row'>
                                <div className='custom_col'>
                                    <span className='bottom_link' style={{ marginTop: 15, fontWeight: 500, }}><p
                                        onClick={() => customProps.handleLogout()}
                                    >Logout</p></span>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </>
    )
}

export default SignUp;