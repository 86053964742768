import { createUseStyles } from 'react-jss'
import { connect } from 'react-redux';
import { Oval } from 'react-loader-spinner';
import './index.css';
import Portfolio from '../Portfolio'
import { useEffect } from 'react';

const useStyles = createUseStyles({
    main: {
        background: '#111111',
        color: '#ffffff',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default function ShopInfoApp(props) {
    const classes = useStyles();
    const staticTiming = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    const timing = []

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    staticTiming.forEach((e) => {
        if (props.data?.timings && props.data?.timings[e]) {
            props.data?.timings[e].forEach(ele => {
                timing.push(
                    <div>
                        <span>{e}</span>
                        <span>{ele.start}</span> -
                        <span>{ele.end}</span>
                    </div>
                )
            })
        }
    })

    if (props.loader) {
        return (
            <Oval
                arialLabel="loading-indicator"
                height={40}
                width={40}
                color='green'
                visible={true}
                wrapperStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    background: 'transparent',
                    zIndex: 99,
                    margin: '1rem 0',
                }}
            />
        )
    }

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className={classes.wrapper}>
                <div className='shop-info-row'>
                    <div className="shop-info-main">
                        <div className="shop-info">
                            <img src={props.data?.image} alt="test" />
                            {/* <div className='shop-detail2'>
                                <h5>{props.data?.name}</h5>
                                <p>{props.data?.location?.address}</p>
                            </div> */}
                        </div>

                    </div>

                    {/* <div className='shop-right-info'>
                        <div className="shop-sec">
                            <h5>Shop Timing</h5>
                            <div className="shop-timing">
                                {timing && timing.length > 0 ?
                                    timing
                                    :
                                    "No Timing Available"
                                }
                            </div>
                        </div>
                    </div>

                    <div className='shop-member-info'>
                        <div className="shop-sec">
                            <h5>Members</h5>
                            <div className="shop-members">
                                {props.data?.shopStaffs && props.data?.shopStaffs.length > 0 ?
                                    props.data?.shopStaffs && props.data?.shopStaffs.map((e) => (
                                        <div className='member-info'>
                                            <img src={e.image} alt={e.fName} />
                                            <span title={e.fName}>{e.fName}</span>
                                        </div>
                                    ))
                                    :
                                    <div>
                                        <span>No Members</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div> */}
                </div>

                {/* <div className='shop-info2'>
                    <div className="shop-details">
                        <div className="shop-sec">
                            <h5>Our Story</h5>
                            <p dangerouslySetInnerHTML={{ __html: props.data?.story }}></p>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className='shop-strems'>
                <Portfolio showingFrom="Shop" shopId={props.shopId} isTheme={props.isTheme} />
            </div>
        </div>
    )
}   