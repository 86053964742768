import React from 'react';
import { createUseStyles } from 'react-jss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { RightArrowIcon, NoVideoIcon } from "../../sharedUtils/globalIcons"
import { Link } from 'react-router-dom';
import moment from 'moment'

const useStyles = createUseStyles({
    Title: {
        color: '#ffffff',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        marginBottom: '0.5rem',
        marginTop: '2rem',
    },
    LightTitle: {
        color: '#111111',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        marginBottom: '0.5rem',
        marginTop: '2rem',
    },
    lightText: {
        color: '#000000'
    },
    darktext: {
        color: '#ffffff'
    }
});

const PastStream = (props) => {
    const classes = useStyles();


    const LeftNavigation = ({ onClick, currentSlide, slideCount, ...props }) => {
        return (
            <div
                {...props}
                className={"prevArrow" + (currentSlide === 0 ? " slick-disabled" : "")}
                onClick={onClick}
                aria-disabled={currentSlide === 0 ? true : false}
            >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 25, display: 'block' }} viewBox="0 0 512 512"><title>Chevron Back</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144" /></svg>
            </div>
        )
    }

    const RightNavigation = ({ onClick, currentSlide, slideCount, ...props }) => {
        return (
            <div
                {...props}
                className={"nextArrow" + (currentSlide === slideCount - 1 ? " slick-disabled" : "")}
                onClick={onClick}
                aria-disabled={currentSlide === slideCount - 1 ? true : false}
            >
                <svg xmlns="http://www.w3.org/2000/svg" style={{ width: 25, display: 'block' }} viewBox="0 0 512 512"><title>Chevron Forward</title><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144" /></svg>
            </div>
        )
    }

    var SlideSettings = {
        arrows: true,
        speed: 500,
        edgeFriction: 1,
        infinite: false,
        focusOnSelect: true,
        swipeToSlide: true,
        accessibility: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        prevArrow: <LeftNavigation onClick />,
        nextArrow: <RightNavigation onClick />,
        draggable: true,
        responsive: [
            {
                breakpoint: 1490,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <div className='video-main recent'>
            <div className='head_title'>
                <h3 className={props.isTheme ? classes.LightTitle : classes.Title}>Past</h3>
                {props.pastStream && props.pastStream.length > 4 ?
                    <Link
                        to={{
                            pathname: '/all',
                            state: {
                                status: ['finished'],
                                shopId: props.showingFrom === "Shop" ? props.shopId : null
                            },
                        }}
                    >
                        <span>View All <RightArrowIcon /></span>
                    </Link>
                    : null
                }
            </div>
            <Slider {...SlideSettings}>
                {props.pastStream && props.pastStream.map((e, index) => (
                    <div className='video-slider' key={index}>
                        <Link
                            to={{
                                pathname: `/video/${e?.slug}`,
                            }}
                        >
                            <div className='video-thumb-items'>
                                <div className='video-dark-overlay'></div>
                                {/* <span className='notify-label'>Notify Me</span> */}
                                <span className='video-time'>{moment(e.startDateAndTime).format('MMM DD, h:mm a')}</span>
                                <img src={e.image} alt={e.name} />
                                <div className='thumb-detail'>
                                    <h5>{e.name}</h5>
                                    <h4 className='video-streamer-info'>
                                        <img src={e.streamer?.image} alt='' />
                                        <span>
                                            {e.streamer?.fName}
                                        </span>
                                    </h4>
                                    <span className='divider'></span>
                                    <h4>
                                        <img src={e.shop?.image} alt={e.shop?.name} />
                                        <span>
                                            {e.shop?.name}
                                            {/* <span className='follow_count'>4.9k followers</span> */}
                                        </span>
                                    </h4>
                                    {/* <div className='action-btn'>
                                        <span className='share'><ShareIcon /></span>
                                        <span className='bookmark'><BookmarkIcon /> 20</span>
                                    </div> */}
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </Slider>

            {props.pastStream && props.pastStream.length <= 0 &&
                <div className={props.isTheme ? classes.lightText : classes.darktext} style={{ textAlign: 'center', width: '100%' }}>
                    <div className={props.isTheme ? 'light_sec no_data_sec' : 'no_data_sec'}>
                        <NoVideoIcon />
                        No Videos Available
                    </div>
                </div>
            }
        </div>
    );
};

export default PastStream;