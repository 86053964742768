import backend from '../../../sharedUtils/backend';

export default new (class authAPI extends backend {
    constructor() {
        super();
    }

    loginsendOtpApi(data) {
        return this.post('auth/send-otp/endUser', data);
    }

    loginverifyOTPApi(data) {
        return this.post('auth/verify-otp/endUser', data);
    }

    loggedAPIReq() {
        return this.get('auth/isloggedin');
    }

    logoutAPI() {
        return this.get('auth/logout');
    }

    googleSSOApi(data) {
        return this.post('auth/google/endUser', data)
    }

    guestLoginApi() {
        return this.post('auth/guest-login');
    }

    addNewAddressApi(data) {
        return this.post('user/add-address', data)
    }

    updateAddressApi(data) {
        return this.post('user/edit-address', data)
    }

    updateProfileApi(data) {
        return this.post('user/edit-profile', data)
    }

    sendOTPForMobileApi(data){
        return this.post('user/send-otp-for-mobile', data)
    }

    verifyOTPForMobileApi(data){
        return this.put('user/verify-otp-for-mobile', data)
    }
})();
