import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner"
import { ArrowUp } from "../sharedUtils/globalIcons"
import "../App.css"
import ShopInfo from '../components/ShopDetails'
import { shopActions } from "../redux/shop/shop";

export default connect(
    state => ({
        loader: state.shop.shop.detailLoader,
        details: state.shop.shop.shopDetail,
        loggedUser: state.login.login.loggedUser,
        isGuestUser: state.login.login.isGuestUser,
    }),
    dispatch => ({
        _getShopDetails: payload => dispatch(shopActions.getShopDetails(payload)),
    }),
)(function ShopDetailApp(props) {

    const slug = props.match?.params?.slug;

    useEffect(() => {
        if (slug !== undefined && slug !== null)
            props._getShopDetails({ slug: slug });
    }, [])

    const [isTheme, setIsTheme] = useState(
        localStorage.getItem('theme') === "false" ? false : true
    );

    useEffect(() => {
        localStorage.setItem('theme', isTheme)
    }, [isTheme])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    const toggleTheme = () => {
        setIsTheme(prev => (!prev));
    }

    console.log("details", props.details)

    return (
        <div className="home-main">
            <Navbar isTheme={isTheme} toggleTheme={toggleTheme} />
            <Banner isTheme={isTheme} data={props.details} showingFrom='shop' />
            {(props.loggedUser?.success || props.isGuestUser?.success) ?
                <ShopInfo isTheme={isTheme} data={props.details} shopId={props.details?._id} {...props} />
                :
                <span style={{ textAlign: 'center', width: '100%', display: 'block', padding: '3rem' }}>You are not logged in!</span>
            }
            <div style={{ position: 'relative' }}>
                <Footer isTheme={isTheme} />
                <div className="scrollTop" onClick={scrollTop}>
                    <span><ArrowUp /></span>
                </div>
            </div>
        </div>
    );

});
