import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner"
import { ArrowUp } from "../sharedUtils/globalIcons";
import "../App.css";
import ShopInfo from '../components/ShopDetails';
import { shopActions } from "../redux/shop/shop";
import InfluencerInfo from "../components/Influencer";

export default connect(
    state => ({
    }),
    dispatch => ({

    }),
)(function InfluencerApp(props) {

    const InfluencerId = props.location?.state?.data?.streamer?._id;

    const InfluencerName = props.match?.params?.incfluencerName;

    const [isTheme, setIsTheme] = useState(
        localStorage.getItem('theme') === "false" ? false : true
    );

    useEffect(() => {
        localStorage.setItem('theme', isTheme)
    }, [isTheme])

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }

    const toggleTheme = () => {
        setIsTheme(prev => (!prev));
    }

    return (
        <div className="home-main">
            <Navbar isTheme={isTheme} toggleTheme={toggleTheme} />
            <Banner isTheme={isTheme} data={props?.location?.state?.data} showingFrom='influencer'/>
            {/* <ShopInfo isTheme={isTheme} data={props.location.state} shopId={shopId} {...props} /> */}
            <InfluencerInfo isTheme={isTheme} data={props?.location?.state?.data} InfluencerId={InfluencerId} {...props} />
            <div style={{ position: 'relative' }}>
                <Footer isTheme={isTheme} />
                <div className="scrollTop" onClick={scrollTop}>
                    <span><ArrowUp /></span>
                </div>
            </div>
        </div>
    );

});
