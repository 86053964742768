import React, { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from '../src/pages/home'
import LiveStream from '../src/pages/livestream'
import { loginActions } from './redux/login/login'
import { connect } from 'react-redux';
import AllStreams from './pages/allStreams';
import Shop from './pages/shop';
import ShopDetail from './pages/shop-detail';
import InfluencerPage from './pages/influencer';

export default connect(
  state => ({
    loggedUser: state.login.login.loggedUser,
    logged: state.login.login.logged,
    isGuestUser: state.login.login.isGuestUser,
  }),
  dispatch => ({
    _isLoggedIn: payload => dispatch(loginActions.isLoggedIn(payload)),
    _guestLogin: payload => dispatch(loginActions.guestLogin(payload)),
  }),
)(function App(props) {

  useEffect(() => {
    let status =
      (localStorage.getItem('loggedIn') === "false" || localStorage.getItem('loggedIn') === undefined || localStorage.getItem('loggedIn') === null)
        ? false : true;

    if (!props.loggedUser.success && !status) {
      props._guestLogin();
    } else {
      props._isLoggedIn();
      localStorage.setItem('loggedIn', true);
    }
  }, [props.loggedUser.success]);

  return (
    <Suspense fallback={'Loading...'}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Home {...props} />}
          />
          <Route
            exact
            path="/video/:slug"
            render={(props) => <LiveStream {...props} />}
          />
          <Route
            exact
            path="/all"
            render={(props) => <AllStreams {...props} />}
          />

          <Route
            exact
            path="/shops"
            render={(props) => <Shop {...props} />}
          />

          <Route
            exact
            path="/shop/:slug"
            render={(props) => <ShopDetail {...props} />}
          />

          <Route
            exact
            path="/influencer"
            render={(props) => <InfluencerPage {...props} />}
          />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
});

// export default App;
