import { createUseStyles } from 'react-jss'
import { Oval } from 'react-loader-spinner';
import './index.css';
import Portfolio from '../Portfolio'
import { useEffect } from 'react';

const useStyles = createUseStyles({
    main: {
        background: '#111111',
        color: '#ffffff',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default function InfluencerApp(props) {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [])

    if (props.loader) {
        return (
            <Oval
                arialLabel="loading-indicator"
                height={40}
                width={40}
                color='green'
                visible={true}
                wrapperStyle={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    background: 'transparent',
                    zIndex: 99,
                    margin: '1rem 0',
                }}
            />
        )
    }

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className={classes.wrapper}>
                <div className='shop-info-row'>
                    <div className="shop-info-main">
                        <div className="shop-info">
                            <img src={props.data?.streamer?.image} alt="test" />
                            <div className={props.isTheme ? 'shop-detail2' : 'dark-shop-detail2'}>
                                <h5>{props.data?.streamer?.fName}</h5>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className='shop-strems'>
                <Portfolio showingFrom="Influencer" InfluencerId={props.InfluencerId} isTheme={props.isTheme} />
            </div>
        </div>
    )
}   