import React, { useState, useEffect } from 'react';
import {
    Nav,
    NavLink,
    Bars,
    NavMenu,
    ShopIcon,
    SignInIcon,
    MenuBarIcon,
    CloseBar,
} from './navbarElement';
import ShoprunLogo from '../../assets/shoprunLogo.png';
import LivestreamIcon from '../../assets/livestream.png';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';
import { ThemeToggleIcon, MapMarkerIcon, CloseIcon } from '../../sharedUtils/globalIcons';
import Modal from '../Modal';
import Login from '../Login';
import SignUp from '../SignUp';
import Message from '../../sharedUtils/globalMessage';
import { Oval } from 'react-loader-spinner';
import { connect } from 'react-redux';
import { authActions } from '../../redux/register/register';
import { loginActions } from '../../redux/login/login';
import { Formik, Field } from 'formik';
import ReactSelect from 'react-select';
import { shopActions } from '../../redux/shop/shop';
import { streamActions } from '../../redux/liveStream/stream';
import Profile from '../Profile';
import { authentication } from '../../firebase-config';
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";


const google = window.google;

const useStyles = createUseStyles({
    addressBar: {
        display: 'inherit',
        position: 'relative',
        // width: '100%',
        // margin: '0 auto'
    },
    addressInput: {
        minHeight: '42px',
        width: '100%',
        padding: '0 2rem',
        boxShadow: '0px 5px 10px rgb(7 6 6 / 6%)',
        borderRadius: 30,
        fontFamily: '"Poppins", sans-serif',
        fontSize: '1rem',
        border: '2px solid #111111'
    },
    addressInputLight: {
        minHeight: '42px',
        width: '100%',
        padding: '0 2rem',
        boxShadow: '0px 5px 10px rgb(7 6 6 / 6%)',
        borderRadius: 30,
        fontFamily: '"Poppins", sans-serif',
        fontSize: '1rem',
        border: '2px solid #cccccc'
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
    },
    searchClear: {
        position: 'absolute',
        right: '8px',
        top: '4px',
        zIndex: 99,
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (max-width: 768px)': {
        navItem: {

        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        },
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },

});

export default connect(
    state => ({
        getResponse: state.reg.reg.sendOtpRes,
        otpResponse: state.login.login.otpResponse,
        loggedUser: state.login.login.loggedUser,
        verifyotpResponse: state.login.login.verifyotpResponse,
        regVerOtp: state.reg.reg.regVerOtp,
        logoutUser: state.login.login.logoutUser,
        googleUser: state.login.login.googleUser,
        googleLoader: state.login.login.googleLoader,
        newAddress: state.login.login.newAddress,
        loadPage: state.stream.stream.loadPage,
        isViewAllLoader: state.stream.stream.isViewAllLoader,
        sendMobileOTPRes: state.login.login.sendMobileOTPRes,
        verifyMobileOTPRes: state.login.login.verifyMobileOTPRes,
        editProfile: state.login.login.editProfile,
    }),
    dispatch => ({
        _getOtp: payload => dispatch(authActions.getOtp(payload)),
        _verifyOtp: payload => dispatch(authActions.verifyOtp(payload)),
        _loginGetOtp: payload => dispatch(loginActions.logingetOtp(payload)),
        _loginVerifyOtp: payload => dispatch(loginActions.loginverifyOtp(payload)),
        _isLoggedIn: payload => dispatch(loginActions.isLoggedIn(payload)),
        _logout: payload => dispatch(loginActions.isLogout(payload)),
        _googleSSO: payload => dispatch(loginActions.googleSSO(payload)),
        _addNewAddress: payload => dispatch(loginActions.addNewAddress(payload)),
        _editAddress: payload => dispatch(loginActions.editAddress(payload)),
        _listAllShop: payload => dispatch(shopActions.listAllShop(payload)),
        _promotedVideos: payload => dispatch(streamActions.promotedVideos(payload)),
        _liveAndUpcoming: payload => dispatch(streamActions.liveAndUpcoming(payload)),
        _pastStreams: payload => dispatch(streamActions.pastStreams(payload)),
        _listAllView: payload => dispatch(streamActions.listAllView(payload)),
        _editProfile: payload => dispatch(loginActions.editProfile(payload)),
        _sendOTPForMobile: payload => dispatch(loginActions.sendOTPForMobile(payload)),
        _verifyOTPForMobile: payload => dispatch(loginActions.verifyOTPForMobile(payload)),
    }),
)(function Navbar(props) {
    const classes = useStyles();
    const customProps = props;

    const sessionToken = new google.maps.places.AutocompleteSessionToken();

    const initAddress = [];

    props.loggedUser?.data?.locations.map((ele) => {
        if (ele.selected)
            initAddress.push(ele);
    })

    const [sideDrawerOpen, setIsSideDrawerOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [signUpModal, setShowSignUp] = useState(false);
    const [profileModal, setProfileModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [otpStatus, setOtpStatus] = useState(false);
    const [otpStatus2, setOtpStatus2] = useState(false);
    const [otpStatus3, setOtpStatus3] = useState(false);
    const [message, setMessage] = useState();
    const [addressModal, setShowAddress] = useState(false);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [results, setResults] = useState([]);
    const [newAdd, setIsAdd] = useState('');
    const [addressObj, setAddressObj] = useState('');

    initAddress.reduce((a, b) => Object.assign(a, b), {})

    const [editAddress, setIsEditAddress] = useState(
        initAddress.reduce((a, b) => Object.assign(a, b), {}).address
    );

    let drawerClasses = ["side-drawer", "mobile-nav"];

    if (sideDrawerOpen) {
        drawerClasses = ["side-drawer", "open", "mobile-nav"];
    }

    const drawerToggleClickHandler = () => {
        setIsSideDrawerOpen(prevState => !prevState.sideDrawerOpen);
    };

    const handleLogin = () => {
        setShow(true);
        setShowSignUp(false);
    }

    const handleSignUp = () => {
        setShowSignUp(true);
        setShow(false);
    }

    useEffect(() => {
        if (props.loggedUser?.success) {
            setShow(false);
        }
    }, [props.loggedUser?.success])

    useEffect(() => {
        if (props.getResponse?.success && isLoading) {
            setIsLoading(false);
            setOtpStatus(true);
            setMessage(props.getResponse);
        } else {
            setIsLoading(false);
            setMessage(props.getResponse);
        }
    }, [props.getResponse])

    useEffect(() => {
        if (props.logoutUser?.success) {
            setMessage(props.logoutUser);
            window.location.reload();
        }
    }, [props.logoutUser?.success])

    useEffect(() => {
        if (props.otpResponse?.success && isLoading2) {
            setIsLoading2(false);
            setOtpStatus2(true);
            setMessage(props.otpResponse);
        } else {
            setIsLoading2(false);
            setMessage(props.otpResponse);
        }
    }, [props.otpResponse?.success])

    useEffect(() => {
        if (props.verifyotpResponse && isLoading2) {
            setShow(false);
            setMessage(props.verifyotpResponse);
            setIsLoading2(false);
            props._isLoggedIn();
        } else {
            setMessage(props.verifyotpResponse);
            setIsLoading2(false);
        }
    }, [props.verifyotpResponse?.success]);

    useEffect(() => {
        if (props.regVerOtp && isLoading) {
            setShowSignUp(false);
            setMessage(props.regVerOtp);
            props._isLoggedIn();
            setIsLoading(false);
        } else {
            setMessage(props.regVerOtp);
            setIsLoading(false);
        }
    }, [props.regVerOtp?.success]);

    useEffect(() => {
        if (props.googleUser.success) {
            setShowSignUp(false);
            setShow(false);
            setMessage(props.googleUser);
            props._isLoggedIn();
            localStorage.setItem('loggedIn', true);
            window.location.reload();
        } else {
            setShowSignUp(false);
            setShow(false);
            setMessage(props.googleUser);
        }
    }, [props.googleUser?.success])

    useEffect(() => {
        if (props.newAddress.success) {
            props._isLoggedIn();
            setMessage(props.newAddress);
            setIsLoading(false);
            setIsNewAddress(false)
        }
    }, [props.newAddress.success])

    const handleSendOTP = (data) => {
        setIsLoading(true);
        props._getOtp(data);
    }

    const handleVerifyOTP = (data) => {
        setIsLoading(true);
        props._verifyOtp(data);
    }

    const handleLoginSendOTP = (data) => {
        setIsLoading2(true);
        props._loginGetOtp(data);
    }

    const handleLoginVerifyOTP = (data) => {
        setIsLoading2(true);
        props._loginVerifyOtp(data);
    }

    const handleLogout = () => {
        let status = localStorage.getItem('loggedIn') === "false" ? false : true;
        if (status) {
            localStorage.setItem('loggedIn', false);
        }
        props._logout();
        window.location.reload();
    }

    const handleGoogleSignIn = (res) => {
        // const provider = new GoogleAuthProvider();
        // signInWithPopup(authentication, provider)
        //     .then((res) => {
        //         if (res._tokenResponse?.idToken) {
        //             console.log("res", res)
        //             props._googleSSO({ id_token: res._tokenResponse?.idToken });
        //         }
        //     }).catch((err) => {
        //         console.log("google auth err ===>>>>", err)
        //     })
        if (res?.tokenId) {
            props._googleSSO({ id_token: res?.tokenId });
        } else {
            console.log("Error: Google auth response ====>>>>>>", res)
            alert(res.error);
        }
    }

    const handleAddress = () => {
        setShowAddress(true);
    }

    const handleNewAddress = () => {
        setIsNewAddress(true);
    }

    let addressList = [];

    addressList = results.map(e => ({
        label: `${e.description}`,
        value: e.place_id,
    }));

    const setPossiblePlacesFunc = address => {
        // Pass the token to the autocomplete service.
        var autocompleteService = new google.maps.places.AutocompleteService();
        autocompleteService.getPlacePredictions(
            {
                input: address,
                sessionToken: sessionToken,
            },
            displaySuggestions => {
                if (displaySuggestions) {
                    setResults(
                        displaySuggestions.map(e => ({
                            description: e.description,
                            place_id: e.place_id,
                        })),
                    );
                }
            },
        );
    };

    const [latLong, setLatLong] = useState({});

    const setLatandLong = place_id => {
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({ placeId: place_id }, function (results, status) {
            // console.log("results", results, status)
            // setResults([...results])
            if (status == 'OK') {
                setLatLong({
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng(),
                });
            }
        });
    };

    const handleAddAddress = () => {
        setIsLoading(true)
        let obj = {
            type: 'Point',
            address: newAdd?.label,
            coordinates: [latLong.latitude, latLong.longitude],
            placeId: newAdd?.value
        }

        if (obj !== undefined && obj !== '') {
            props._addNewAddress({
                location: obj
            })
        }
    }

    const handleChangeAddress = (e) => {
        setIsAdd(e);
    }

    const updateAddress = (event) => {
        setIsEditAddress(event.address);
        setAddressObj(event);
    }

    const searchInData = (event) => {
        let a = { ...props }
        a.limit = 12;

        if (event.target.value.length > 2) {
            if (props.searchFor === 'Shops') {
                props._listAllShop({ search: event.target.value, a });
            }
            else if (props.searchFor === "stream") {
                props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc', search: event.target.value })
                props._liveAndUpcoming({ status: ['created'], sortType: 'asc', search: event.target.value })
                props._pastStreams({ status: ['finished'], sortType: 'desc', search: event.target.value })
            }
            else if (props.searchFor === "allStream") {
                if (props.streamStatus)
                    props._listAllView({ status: props.streamStatus, a, search: event.target.value, sortType: 'asc' })
            }
        }
    }

    const clearSearch = () => {
        const inputVal = document.getElementById('yourAddress').value = '';

        let a = { ...props }
        a.limit = 12;
        if (props.searchFor === 'Shops' && inputVal !== '' && inputVal !== undefined) {
            props._listAllShop({ a });
        }
        else if (props.searchFor === "stream" && inputVal !== '' && inputVal !== undefined) {
            props._promotedVideos({ status: ['promoted', 'broadcasting'], sortType: 'asc' })
            props._liveAndUpcoming({ status: ['created'], sortType: 'asc' })
            props._pastStreams({ status: ['finished'], sortType: 'desc' })
        }
        else if (props.searchFor === "allStream" && inputVal !== '' && inputVal !== undefined) {
            if (props.streamStatus)
                props._listAllView({ status: props.streamStatus, a, sortType: 'asc' })
        }
    }

    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            const context = this;
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => {
                timeout = null;
                func.apply(context, args);
            }, wait);
        };
    }

    const debounceOnChange = React.useCallback(debounce(searchInData, 600), []);

    useEffect(() => {
        if (props.sendMobileOTPRes?.success) {
            setOtpStatus3(true);
            setIsLoading(false);
            setMessage(props.sendMobileOTPRes);
        }
    }, [props.sendMobileOTPRes?.success])

    useEffect(() => {
        if (props.verifyMobileOTPRes?.success) {
            setIsLoading(false);
            setProfileModal(false);
            setMessage(props.verifyMobileOTPRes);
            setOtpStatus3(false);
        }
    }, [props.verifyMobileOTPRes?.success])

    useEffect(() => {
        if (props.editProfile?.success) {
            props._isLoggedIn();
            setIsLoading(false);
            // setProfileModal(false);
            setMessage(props.editProfile);
        }
    }, [props.editProfile?.data])

    const handleMobileOTP = (data) => {
        setIsLoading(true);
        props._sendOTPForMobile(data);
    }

    const handleVerifyMobile = (isMobile, isOTP) => {
        const newObj = {
            otp: isOTP,
            mobile: isMobile?.mobile,
            countryCode: isMobile?.countryCode,
        }
        setIsLoading(true);
        props._verifyOTPForMobile(newObj);
    }

    const getAddress = (data) => {
        if (data) {
            var str = data?.address;
            var cityAndRest = str?.split(',');
            var city = cityAndRest && cityAndRest[0];
            var stateAndZip = cityAndRest && cityAndRest[1].trim().split(' ');
            var state = stateAndZip && stateAndZip[0];
            var zip = stateAndZip && stateAndZip[1];
        }
        return city;
    }

    return (
        <div className={props.isTheme ? 'headerLight-menu' : 'header-menu'}>

            <Oval
                arialLabel="loading-indicator"
                height={40}
                width={40}
                color='green'
                visible={props.googleLoader || props.loadPage || props.isViewAllLoader}
                wrapperStyle={{
                    justifyContent: 'center',
                    position: 'absolute',
                    alignItems: 'center',
                    left: 0,
                    right: 0,
                    top: 0,
                    height: '100%',
                    background: 'rgb(0 0 0 / 9%)',
                    zIndex: 99,
                }}
            />

            <Nav>
                <div className={classes.wrapper}>
                    <div className='mobile-menu'>
                        <Link to='/'>
                            <img src={ShoprunLogo} alt="shoprun logo" />
                        </Link>
                        <span
                            className={props.isTheme ? 'ThemeLight' : 'ThemeDark'}
                            onClick={props.toggleTheme}
                            style={{ display: 'flex', marginRight: '4rem' }}
                        >
                            <ThemeToggleIcon />
                        </span>
                        {sideDrawerOpen ?
                            <CloseBar onClick={() => setIsSideDrawerOpen(false)} />
                            :
                            <Bars onClick={drawerToggleClickHandler} />
                        }
                    </div>

                    {/* Mobile Menu */}
                    <div className={drawerClasses.join(" ")}>
                        <Link to='/'>
                            <span>Livestreams</span>
                        </Link>
                        <Link to='/shops'>
                            <span>Shops</span>
                        </Link>
                        {props.loggedUser?.success ?
                            <span
                                className={props.isTheme ? 'ThemeLight menuItemLight login_user' : 'ThemeDark menuItem login_user'}
                                style={{ display: 'flex' }}>
                                <SignInIcon /> {props.loggedUser?.data?.fName.split(" ")[0]}
                                <div className='logout-sec'>
                                    <button className='logout-btn' onClick={handleLogout}>Logout</button>
                                </div>
                            </span>
                            :
                            <span
                                onClick={handleLogin}
                                className={props.isTheme ? 'ThemeLight menuItemLight' : 'ThemeDark menuItem'}
                                style={{ display: 'flex' }}>
                                <SignInIcon /> Sign in
                            </span>
                        }
                    </div>

                    {/* Desktop Menu */}
                    <NavMenu>
                        <NavLink to='/'>
                            <MenuBarIcon />
                        </NavLink>
                        <NavLink to='/'>
                            <img src={ShoprunLogo} alt="shoprun logo" />
                        </NavLink>
                        <span className={classes.addressBar, 'headSearchClose'}>
                            <input
                                className={props.isTheme ? classes.addressInputLight : classes.addressInput}
                                type='text'
                                name='yourAddress'
                                id='yourAddress'
                                placeholder='Search'
                                onChange={debounceOnChange}
                            />
                            <span onClick={clearSearch} className={classes.searchClear}><CloseIcon /></span>
                        </span>

                        <span
                            onClick={
                                props.loggedUser?.success ?
                                    handleAddress :
                                    () => alert('Sign In Required!')
                            }
                            className={props.isTheme ? 'ThemeLight addressMenuLight' : 'ThemeDark addressMenu'}
                        >
                            <span>
                                <MapMarkerIcon />
                                {props.loggedUser?.success &&
                                    props.loggedUser?.data?.locations.length > 0 ?
                                    props.loggedUser?.data?.locations.map((e) => e.selected && (
                                        getAddress(e)
                                    ))
                                    :
                                    'Address'
                                }
                            </span>
                        </span>

                        <NavLink to='/'>
                            <span style={{ display: 'flex' }}><img src={LivestreamIcon} alt='livestream' /></span> Livestreams
                        </NavLink>
                        <NavLink to='/shops'>
                            <span style={{ display: 'flex' }}><ShopIcon /></span> Shops
                        </NavLink>
                        {props.loggedUser?.success ?
                            <span
                                className={props.isTheme ? 'ThemeLight menuItemLight login_user' : 'ThemeDark menuItem login_user'}
                                style={{ display: 'flex' }}
                                onClick={() => setProfileModal(true)}
                            >
                                <SignInIcon /> {props.loggedUser?.data?.fName.split(" ")[0]}
                                {/* <div className='logout-sec'>
                                    <button className='logout-btn' onClick={handleLogout}>Logout</button>
                                </div> */}
                            </span>
                            :
                            <span
                                onClick={handleLogin}
                                className={props.isTheme ? 'ThemeLight menuItemLight' : 'ThemeDark menuItem'}
                                style={{ display: 'flex' }}>
                                <SignInIcon /> Sign in
                            </span>
                        }
                        <span className={props.isTheme ? 'ThemeLight' : 'ThemeDark'} onClick={props.toggleTheme} style={{ display: 'flex' }}><ThemeToggleIcon /></span>
                    </NavMenu>
                </div>
            </Nav>

            <Modal
                title="SIGN IN"
                onClose={() => setShow(false)}
                show={show}
            >
                <Oval
                    arialLabel="loading-indicator"
                    height={30}
                    width={30}
                    color='green'
                    visible={isLoading2}
                    wrapperStyle={{
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '100%',
                        background: 'rgb(0 0 0 / 9%)',
                        zIndex: 99,
                    }}
                />
                <Login
                    otpStatus={otpStatus2}
                    handleLogin={handleSignUp}
                    handleLoginSendOTP={handleLoginSendOTP}
                    handleLoginVerifyOTP={handleLoginVerifyOTP}
                    handleGoogleSignIn={handleGoogleSignIn}
                />
            </Modal>

            <Modal title="SIGN UP" onClose={() => setShowSignUp(false)} show={signUpModal}>
                <Oval
                    arialLabel="loading-indicator"
                    height={30}
                    width={30}
                    color='green'
                    visible={isLoading}
                    wrapperStyle={{
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '100%',
                        background: 'rgb(0 0 0 / 9%)',
                        zIndex: 99,
                    }}
                />
                <SignUp
                    otpStatus={otpStatus}
                    handleSignUp={handleLogin}
                    handleSendOTP={handleSendOTP}
                    handleGoogleSignIn={handleGoogleSignIn}
                    handleVerifyOTP={handleVerifyOTP}
                />
            </Modal>

            <Modal title="My Addresses" onClose={() => setShowAddress(false)} show={addressModal}>
                <Oval
                    arialLabel="loading-indicator"
                    height={30}
                    width={30}
                    color='green'
                    visible={isLoading}
                    wrapperStyle={{
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '100%',
                        background: 'rgb(0 0 0 / 9%)',
                        zIndex: 99,
                    }}
                />

                <Formik
                    initialValues={{}}
                    onSubmit={(values, { setSubmitting }) => {
                        setIsLoading(true);
                        let obj = {
                            type: 'Point',
                            address: addressObj.address,
                            coordinates: addressObj.coordinates,
                            placeId: addressObj.placeId,
                            locationId: addressObj._id,
                            selected: true,
                        }
                        if (addressObj !== '' && addressObj !== undefined)
                            props._editAddress(obj);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        isSubmitting,
                    }) => (
                        <form onSubmit={handleSubmit} className='address_form'>
                            {!isNewAddress ?
                                <>
                                    <div className='user_address_list'>
                                        <div role="group" aria-labelledby="my-radio-group">
                                            {customProps.loggedUser?.data?.locations?.length > 0 ?
                                                customProps.loggedUser?.data?.locations.map((e) => (
                                                    <label>
                                                        <Field
                                                            type="radio"
                                                            name="address"
                                                            id="address"
                                                            value={e.address}
                                                            checked={editAddress === e.address}
                                                            onChange={() => updateAddress(e)}
                                                        />
                                                        <span>{e.address}</span>
                                                    </label>
                                                ))
                                                :
                                                <div>No Addresses Added</div>
                                            }
                                        </div>
                                    </div>

                                    <div className='address_action_btn'>
                                        <button type='submit' className='submit_btn' disabled={customProps.loggedUser?.data?.locations?.length > 0 ? false : true}>Confirm</button>
                                        <button type='button' className='btn_link' onClick={handleNewAddress}>+ Add New</button>
                                    </div>
                                </>
                                :
                                <>
                                    <div>
                                        <ReactSelect
                                            name='address_list'
                                            placeholder='Search address'
                                            isSearchable={true}
                                            className='react-select-custom'
                                            isClearable={true}
                                            value={values.address_list}
                                            menuPortalTarget={document.querySelector('body')}
                                            styles={{
                                                menuPortal: provided => ({ ...provided, zIndex: 99999 }),
                                                menu: provided => ({ ...provided, zIndex: 99999 })
                                            }}
                                            onInputChange={e => {
                                                if (e) setPossiblePlacesFunc(e);
                                            }}
                                            onChange={e => {
                                                setFieldValue('address_list', e)
                                                setLatandLong(e.value)
                                                handleChangeAddress(e);
                                            }}
                                            onBlur={e => {
                                                handleBlur(e);
                                            }}
                                            options={addressList}
                                        />
                                    </div>
                                    <div className='address_action_btn' style={{ marginTop: 20 }}>
                                        <button
                                            type='button'
                                            onClick={() => handleAddAddress()}
                                            className='submit_btn'
                                        >
                                            Submit
                                        </button>
                                        <button
                                            type='button'
                                            onClick={() => setIsNewAddress(false)}
                                            className='btn_link'
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                </>
                            }
                        </form>
                    )}
                </Formik>

            </Modal>


            <Modal title="Profile" onClose={() => setProfileModal(false)} show={profileModal}>
                <Oval
                    arialLabel="loading-indicator"
                    height={30}
                    width={30}
                    color='green'
                    visible={isLoading}
                    wrapperStyle={{
                        justifyContent: 'center',
                        position: 'absolute',
                        alignItems: 'center',
                        left: 0,
                        right: 0,
                        top: 0,
                        height: '100%',
                        background: 'rgb(0 0 0 / 9%)',
                        zIndex: 99,
                    }}
                />
                <Profile
                    {...props}
                    otpStatus={otpStatus3}
                    handleLoading={setIsLoading}
                    handleLogout={handleLogout}
                    handleLoginSendOTP={handleLoginSendOTP}
                    handleMobileOTP={handleMobileOTP}
                    handleVerifyMobile={handleVerifyMobile}
                />
            </Modal>

            <Message data={message} />

        </div >
    );
});