import backend from '../../../sharedUtils/backend';

export default new (class authAPI extends backend {
    constructor() {
        super();
    }

    queryBuilder(att) {
        let queryString = `?sort=${att.sort}&sortType=${att.sortType}`;
        queryString += '&skip=' + (att.page - 1) * att.limit;
        queryString += '&limit=' + att.limit;

        return queryString;
    }

    shopCategoryAPI(data) {
        let str = ''
        str += '?limit=' + data.limit
        str += '&skip=' + data.skip

        return this.get('shop/shop-category/list' + str);
    }

    shopListAPI(query, data) {
        let str = query.shop ? this.queryBuilder(query.shop) : '';

        if (data.category) {
            str += '&category=' + data.category
        }
        if (data.search) {
            str += '&search=' + data.search
        }

        return this.get('shop/list' + str);
    }

    getShopDetailsAPI(data) {
        return this.get(`shop/${data?.slug}`);
    }

    // liveStreamsAPI(query, data) {
    //     let str = query.stream ? this.queryBuilder(query.stream) : '';
    //     if (data.status) {
    //         data.status.map((e) => {
    //             str += '&status=' + e;
    //         })
    //     }
    //     if (data.category)
    //         str += '&category=' + data.category

    //     return this.post('live-stream/list' + str);
    // }



    // listAllVideoAPI(query, data) {
    //     try {
    //         let str = query.stream ? this.queryBuilder(query.stream) : '';
    //         if (data.status) {
    //             data.status.map((e) => {
    //                 str += '&status=' + e;
    //             })
    //         }
    //         if (data.category)
    //             str += '&category=' + data.category
    //         return this.post('live-stream/list' + str);
    //     } catch (err) {
    //         console.log('catch new errr', err)
    //     }
    // }

})();
