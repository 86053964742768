import React from 'react';
import { createUseStyles } from 'react-jss'
import './style.css'
import Logo from '../../assets/shoprunWhiteLogo.png'
import { CallIcon, InstagramIcon, FacebookIcon, CopyRightIcon } from '../../sharedUtils/globalIcons'

const useStyles = createUseStyles({
    addressBar: {
        display: 'inherit',
        width: '100%',
        minWidth: '518px',
        maxWidth: '518px',
        margin: '0 auto'
    },
    addressInput: {
        minHeight: '42px',
        width: '100%',
        padding: '0 2rem',
        border: '0',
        boxShadow: '0px 5px 10px rgb(7 6 6 / 6%)',
        borderRadius: 30,
        fontFamily: '"Poppins", sans-serif',
        fontSize: '1rem',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },

});

const Footer = (props) => {
    const classes = useStyles();

    return (
        <div className={props.isTheme ? 'lightFooter footer' : 'footer'}>
            <div className={classes.wrapper}>
                <div className='flex-row'>
                    <div className='flex-col'>
                        <div className='info'>
                            <img src={Logo} alt='Shoprun' />
                            <span className='call'><CallIcon /> Support: 1300 585 888</span>
                        </div>
                    </div>
                    <div className='flex-col'>
                        <div className='footer-menu'>
                            <ul>
                                <li className='menu-title'>Popular suburbs</li>
                                <li><a href='#!'>Castle Hill</a></li>
                                <li><a href='#!'>Kellyville</a></li>
                                <li><a href='#!'>Cherrybrook</a></li>
                                <li><a href='#!'>Baulkham Hills</a></li>
                                <li><a href='#!'>Pennant Hills</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex-col'>
                        <div className='footer-menu'>
                            <ul>
                                <li className='menu-title'>About</li>
                                <li><a href='#!'>About Us</a></li>
                                <li><a href='#!'>How It Works</a></li>
                                <li><a href='#!'>For Shops</a></li>
                                <li><a href='#!'>For Hosts</a></li>
                                <li><a href='#!'>Terms & Conditions</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className='flex-col'>
                        <div className='footer-menu'>
                            <ul>
                                <li className='menu-title'>Keep In Touch</li>
                                <li><a href='#!'>Contact Us</a></li>
                                <li><a href='#!'>Help Center</a></li>
                                <li className='social-action'>
                                    <a href='#!'><FacebookIcon /></a>
                                    <a href='#!'><InstagramIcon /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='copyright'>
                <p><CopyRightIcon /> Copyright - Shoprun 2022</p>
            </div>
        </div >
    );
};

export default Footer;
