import React, { useState } from 'react';
import { createUseStyles } from 'react-jss'
import BannerImg from '../../assets/banner.jpg'
import Modal from '../Modal';

const useStyles = createUseStyles(() => ({
    BannerMain: {
        background: (props) => `linear-gradient(135deg, rgb(17 17 17 / 49%) 0, rgb(17 17 17 / 25%) 100%), url(
            ${props.showingFrom === 'influencer' && props.data?.streamer?.coverImage ? props.data?.streamer?.coverImage : (props.data?.coverImage || BannerImg)}
            )`,
        width: '100%',
        textAlign: 'center',
        backgroundSize: 'cover !important',
        backgroundRepeat: 'no-repeat !important',
        height: 'calc(100vh - 55vh)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundPosition: 'center center !important',
    },
    '@media screen and (max-width: 480px)': {
        BannerMain: {
            height: '100%',
            padding: '2rem 1rem'
        }
    },
    Title: {
        fontSize: '1.3rem',
        color: '#ffffff',
        fontWeight: '500',
        margin: 0,
    },
    text2: {
        fontWeight: 600,
        fontSize: '2.5rem',
        display: 'block',
    },
    btnStyle: {
        display: 'block',
        border: '1px solid #ffffff',
        background: 'rgb(0 0 0 / 10%)',
        color: '#ffffff',
        borderRadius: '50px',
        padding: '0.5rem 2rem',
        fontSize: '1rem',
        fontWeight: '500',
        margin: '15px auto',
        cursor: 'pointer',
    },
    Title2: {
        fontSize: '3rem',
        color: '#ffffff',
        fontWeight: 'bold',
        margin: 0,
    },
    text1: {
        fontWeight: 600,
        fontSize: '2.5rem',
        display: 'block',
    },
}));

const Navbar = (props) => {
    const classes = useStyles(props);

    const staticTiming = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
    const timing = []

    staticTiming.forEach((e) => {
        if (props.data?.timings && props.data?.timings[e]) {
            props.data?.timings[e].forEach(ele => {
                timing.push(
                    <div>
                        <span>{e}</span>
                        <span>{ele.start === '00:00 AM' ? '12:00 AM' : ele.start}</span> -
                        <span>{ele.end}</span>
                    </div>
                )
            })
        }
    })

    const [show, setShow] = useState(false);
    const [tabs, setTabs] = useState('story');

    const handleChangeTab = (tab) => {
        setTabs(tab);
    }

    return (
        <div className={classes.BannerMain}>
            {props.showingFrom === 'shop' ?
                <h1 className={classes.Title}>
                    <div className='mobile-shop-image'>
                        <img src={props.data?.image} />
                    </div>
                    <span className={classes.text2}>{props.data?.name}</span>
                    {props.data?.location?.address}
                    <button className={classes.btnStyle} onClick={() => setShow(true)}>More Info</button>
                </h1>
                :
                <h1 className={classes.Title2}>
                    <span className={classes.text1}>Your favourite Stores</span>
                    at your fingertips!
                </h1>
            }


            <Modal
                onClose={() => setShow(false)}
                show={show}
                showingFrom='shop'
            >
                <div className='tabs-main'>
                    <div className='tabs'>
                        <button className={tabs === 'story' ? 'tabs_active_btn' : 'tabs_btn'} onClick={() => handleChangeTab('story')}>Our Story</button>
                        <button className={tabs === 'store' ? 'tabs_active_btn' : 'tabs_btn'} onClick={() => handleChangeTab('store')}>Store Timing</button>
                        <button className={tabs === 'member' ? 'tabs_active_btn' : 'tabs_btn'} onClick={() => handleChangeTab('member')}>Team Members</button>
                    </div>

                    {tabs === 'story' &&
                        <div className='shop-info2'>
                            <div className="shop-details">
                                <div className="shop-sec">
                                    <p dangerouslySetInnerHTML={{ __html: props.data?.story }}></p>
                                </div>
                            </div>
                        </div>
                    }

                    {tabs === 'store' &&
                        <div className='shop-right-info'>
                            <div className="shop-sec">
                                <div className="shop-timing">
                                    {timing && timing.length > 0 ?
                                        timing
                                        :
                                        "No Timing Available"
                                    }
                                </div>
                            </div>
                        </div>
                    }

                    {tabs === 'member' &&
                        <div className='shop-member-info'>
                            <div className="shop-sec">
                                <div className="shop-members">
                                    <div className='member-info'>
                                        <img src={props.data?.ownerId?.image} alt={props.data?.ownerId?.fName} />
                                        <span title={props.data?.ownerId?.fName}>{props.data?.ownerId?.fName}</span>
                                    </div>
                                    {props.data?.shopStaffs && props.data?.shopStaffs.length > 0 &&
                                        props.data?.shopStaffs && props.data?.shopStaffs.map((e) => (
                                            <div className='member-info'>
                                                <img src={e.image} alt={e.fName} />
                                                <span title={e.fName}>{e.fName}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    );
};

export default Navbar;