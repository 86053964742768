import React, { useState } from 'react'
import '../Login/index.css'
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { GoogleIcon } from '../../sharedUtils/globalIcons'
import OtpInput from 'react-otp-input';
import { createUseStyles } from 'react-jss';
import { GoogleLogin } from 'react-google-login';
import { connect } from 'react-redux';

const useStyles = createUseStyles({
    otpInputStyle: {
        width: '35px !important',
        height: '35px !important',
        margin: '0 0.5rem',
        fontSize: '1.3rem',
        fontWeight: 700,
        background: '#eff0f4',
        border: '1px solid #53b029',
        color: '#53b029',
        borderRadius: '3px',
        padding: '0 !important'
    },
});

export default connect(
    state => null,
    dispatch => ({
    }),
)(function Login(props) {
    const classes = useStyles();
    const customProps = props;

    const [isMobile, setIsMobile] = useState();
    const [isOTP, setIsOTP] = useState('');

    const handlePhone = (country, phoneNumber) => {
        const phone = {
            countryCode: '+' + country.dialCode,
            mobile: phoneNumber.replace(country.dialCode, ''),
        }
        setIsMobile(phone);
    }

    const handleChangeOTP = (otp) => {
        setIsOTP(otp);
    }

    return (
        <div className='login_form'>
            <Formik
                initialValues={{ phone: '' }}
                onSubmit={(values, { setSubmitting }) => {
                    const obj = {
                        otp: isOTP,
                        mobile: isMobile?.mobile,
                        countryCode: isMobile?.countryCode,
                    }
                    customProps.handleLoginVerifyOTP(obj);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        {!customProps.otpStatus &&
                            <div className='custom_row'>
                                <div className='custom_col'>
                                    <span className='input-label'>Enter your mobile number</span>
                                </div>
                                <div className='custom_col'>
                                    <PhoneInput
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                            autoFocus: true
                                        }}
                                        country={'au'}
                                        name='phone'
                                        value={values.phone}
                                        onChange={(phoneNumber, country, e) => { handlePhone(country, phoneNumber) }}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='submit-btn'>
                                    <button type='button' onClick={() => customProps.handleLoginSendOTP(isMobile)}>
                                        Next
                                    </button>
                                </div>
                            </div>
                        }

                        {customProps.otpStatus &&
                            <div className='custom_row'>
                                <div className='custom_col' style={{ paddingLeft: 10, paddingBottom: 10 }}>
                                    <span className='input-label'>Verify OTP</span>
                                </div>
                                <div className='custom_col'>
                                    <OtpInput
                                        value={isOTP}
                                        onChange={handleChangeOTP}
                                        numInputs={6}
                                        separator={<span>-</span>}
                                        shouldAutoFocus
                                        inputStyle={classes.otpInputStyle}
                                        hasErrored={true}
                                        isInputNum={true}
                                    />
                                </div>
                                <div className='submit-btn'>
                                    <button type='submit'>
                                        Verify
                                    </button>
                                </div>
                                <div className='resend_otp'>
                                    <button type='button' className='link-btn' onClick={() => customProps.handleLoginSendOTP(isMobile)}>
                                        Resend OTP
                                    </button>
                                </div>
                            </div>
                        }

                        <div className='custom_row'>
                            <div className='custom_col'>
                                <div className='divider_text'>
                                    <span></span>
                                    or
                                    <span></span>
                                </div>
                            </div>

                            <div className='custom_col'>
                                <div className='google-signIn'>
                                    {/* <button
                                        type='button'
                                        onClick={props.handleGoogleSignIn}
                                    >
                                        <GoogleIcon /> Sign In With Google
                                    </button> */}
                                    <GoogleLogin
                                        clientId="667514731244-a8feu1a6na78ndjd4rk6o19f7f2jkead.apps.googleusercontent.com"
                                        render={renderProps => (
                                            <button
                                                onClick={renderProps.onClick}
                                                disabled={renderProps.disabled}>
                                                <GoogleIcon /> Sign In With Google
                                            </button>
                                        )}
                                        // buttonText="Sign In With Google"
                                        onSuccess={props.handleGoogleSignIn}
                                        onFailure={props.handleGoogleSignIn}
                                        cookiePolicy={'single_host_origin'}
                                        isSignedIn={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='custom_row'>
                            <div className='custom_col'>
                                <span className='bottom_link'>Don't have an account? <p onClick={customProps.handleLogin}>Sign Up</p></span>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
});