import React, { useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'
import { VideoIcon } from "../../sharedUtils/globalIcons"
import { connect } from 'react-redux';
import { streamActions } from '../../redux/liveStream/stream'
import Pagination from '../Pagination';
import { Oval } from 'react-loader-spinner';
import moment from 'moment'
import { Link } from 'react-router-dom'
import Category from '../Portfolio/categories'
import { loginActions } from '../../redux/login/login'

const useStyles = createUseStyles({
    Title: {
        color: '#ffffff',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        marginBottom: '2rem',
        textAlign: 'center',
    },
    LightTitle: {
        color: '#111111',
        fontWeight: 600,
        fontSize: '1.875rem',
        paddingLeft: 10,
        textAlign: 'center',
    },
    main: {
        background: '#111111',
        color: '#ffffff',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    lightmain: {
        background: '#ffffff',
        color: '#ffffff',
        paddingTop: '2rem',
        paddingBottom: '4rem',
    },
    wrapper: {
        marginLeft: 'auto',
        marginRight: 'auto',
        maxWidth: '1200px',
        paddingLeft: '10px',
        paddingRight: '10px',
        width: '100%',
        color: '#ffffff'
    },
    '@media screen and (max-width: 576px)': {
        wrapper: {
            maxWidth: '100%',
        }
    },
    '@media screen and (min-width: 576px)': {
        wrapper: {
            maxWidth: '540px',
        }
    },

    '@media screen and (min-width: 768px)': {
        wrapper: {
            maxWidth: '720px',
        }
    },
    '@media screen and (min-width: 992px)': {
        wrapper: {
            maxWidth: '960px',
        }
    },
    '@media screen and (min-width: 1200px)': {
        wrapper: {
            maxWidth: '1200px',
        }
    },
});

export default connect(
    state => ({
        listView: state.stream.stream.listView,
        limit: state.stream.stream.limit,
        isViewAllLoader: state.stream.stream.isViewAllLoader,
        categories: state.stream.stream.categories,
        categoryLoader: state.stream.stream.categoryLoader,
        loggedUser: state.login.login.loggedUser,
        isCategory: state.stream.stream.isCategory,
    }),
    dispatch => ({
        _listAllView: payload => dispatch(streamActions.listAllView(payload)),
        _categories: payload => dispatch(streamActions.categories(payload)),
    }),
)(function ListView(props) {

    const status = props.history?.location?.state?.status;
    const shopId = props.history?.location?.state?.shopId;

    const [isCategory, setCategory] = useState('');

    useEffect(() => {
        props._categories({ limit: 20, skip: 0 })
    }, []);

    useEffect(() => {
        let a = { ...props }
        a.limit = 12;

        if (status.includes('finished')) {
            if (shopId !== null || shopId !== undefined || shopId !== "")
                props._listAllView({ status: status, a, shopId: shopId, category: props.isCategory, sortType: 'desc' })
            else
                props._listAllView({ status: status, a, category: props.isCategory, sortType: 'desc' })
        }
        else if (shopId !== null || shopId !== undefined || shopId !== "") {
            props._listAllView({ status: status, a, shopId: shopId, category: props.isCategory, sortType: 'asc' })
        }
        else {
            props._listAllView({ status: status, a, category: props.isCategory, sortType: 'asc' })
        }

    }, [])

    const classes = useStyles();

    let title = ''

    status && status.map((e) => {
        if (e === 'promoted')
            return title = 'Live & Promoted'
        else if (e === 'finished')
            return title = 'Past'
        else
            return title = 'Upcoming'
    })

    const filterCategory = (category) => {
        setCategory(category);
        let a = { ...props }
        a.limit = 12;

        if (category === 'all') {
            if (shopId !== null || shopId !== undefined || shopId !== "")
                props._listAllView({ status: status, a, shopId: shopId, sortType: 'asc' })
            else
                props._listAllView({ status: status, a, sortType: 'asc' })
        } else if (status.includes('finished')) {
            if (shopId !== null || shopId !== undefined || shopId !== "")
                props._listAllView({ status: status, a, shopId: shopId, category: category, sortType: 'desc' })
            else
                props._listAllView({ status: status, a, category: category, sortType: 'desc' })
        } else {
            if (shopId !== null || shopId !== undefined || shopId !== "")
                props._listAllView({ status: status, a, shopId: shopId, category: category, sortType: 'asc' })
            else
                props._listAllView({ status: status, a, category: category, sortType: 'asc' })
        }
    }

    return (
        <div className={props.isTheme ? classes.lightmain : classes.main}>
            <div className={classes.wrapper} style={{ marginBottom: 60 }}>
                <h2 className={props.isTheme ? classes.LightTitle : classes.Title}>{title}</h2>

                {props.categoryLoader ?
                    <Oval
                        arialLabel="loading-indicator"
                        height={40}
                        width={40}
                        color='green'
                        visible={true}
                        wrapperStyle={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            background: 'transparent',
                            zIndex: 99,
                        }}
                    /> :
                    <Category
                        isCategory={props.isCategory}
                        isTheme={props.isTheme}
                        categories={props.categories.data}
                        filterCategory={filterCategory}
                    />
                }
            </div>

            <div className='video-main recent all list-all'>
                <div className='list-container'>

                    {props.listView && props.listView.length > 0 &&
                        (props.listView && props.listView.map((e, index) => (
                            <div className='list-items'>
                                <Link
                                    to={{
                                        pathname: `/video/${e?.slug}`,
                                    }}
                                >
                                    <div className='video-slider'>
                                        <div className='video-thumb-items'>
                                            <div className='video-dark-overlay'></div>
                                            {e.status === 'broadcasting' ?
                                                <span className='video-status'><VideoIcon /> Live</span>
                                                :
                                                <>
                                                    {e.status === 'promoted' ?
                                                        <span className='video-onAir'><VideoIcon /> Promoted</span>
                                                        :
                                                        <span className='video-time'>{moment(e.startDateAndTime).format('MMM DD, h:mm a')}</span>
                                                    }
                                                </>
                                            }
                                            <img src={e.image} alt={index} />
                                            <div className='thumb-detail'>
                                                <h5>{e.name}</h5>
                                                <h4 className='video-streamer-info'>
                                                    <img src={e.streamer?.image} alt='' />
                                                    <span>
                                                        {e.streamer?.fName}
                                                    </span>
                                                </h4>
                                                <span className='divider'></span>
                                                <h4>
                                                    <img src={e.shop?.image} alt='' />
                                                    <span>
                                                        {e.shop?.name}
                                                    </span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        )))
                    }
                </div>
            </div>
            <Pagination status={status} isTheme={props.isTheme} shopId={shopId} isCategory={isCategory} />
        </div>
    );
});