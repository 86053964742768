/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import history from './utils/history';
import registerData from './redux/register/index';
import loginData from './redux/login/index';
import liveStreamData from './redux/liveStream/index';
import shopData from './redux/shop/index'

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    reg: registerData,
    login: loginData,
    stream: liveStreamData,
    shop: shopData,
    ...injectedReducers,
  });

  return rootReducer;
}
