import React, { useEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import './style.css';
import { CSSTransition } from "react-transition-group";
import { CloseIcon } from "../../sharedUtils/globalIcons"

const Modal = props => {

    const box = useRef(null);

    const closeOnEscapeKeyDown = e => {
        if ((e.charCode || e.keyCode) === 27) {
            props.onClose();
        }
    };

    useEffect(() => {
        document.body.addEventListener("keydown", closeOnEscapeKeyDown);

        return function cleanup() {
            document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
        };
    }, []);

    return ReactDOM.createPortal(
        <CSSTransition
            in={props.show}
            unmountOnExit
            timeout={{ enter: 0, exit: 300 }}
        >
            <div id='modal-popup' className="modal" onClick={() => props.onClose()}>
                <div className={props.showingFrom === 'shop' ? "shop-modal-content modal-content" : "modal-content"} onClick={e => e.stopPropagation()}>
                    {props.from !== 'LiveStream' &&
                        <div className="modal-closeBtn">
                            <button onClick={props.onClose} className="button">
                                <CloseIcon />
                            </button>
                        </div>
                    }
                    {props.title &&
                        <div className="modal-header">
                            <h4 className="modal-title">{props.title}</h4>
                        </div>
                    }
                    <div className="modal-body">{props.children}</div>
                </div>
            </div>
        </CSSTransition >,
        document.getElementById("root")
    );
};

export default Modal;
