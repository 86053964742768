import React, { useEffect, useState } from "react";
import { StreamChat } from 'stream-chat';
import { Chat, Channel, ChannelHeader, MessageInput, MessageList, Window, SendButton } from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import '../Chat/style.css';

export default function ChatApp(props) {

    let userData = null;

    if (props.isGuestUser?.data) {
        userData = props.isGuestUser?.data;
    } else {
        userData = props.loggedUser?.data;
    }

    const [chatClient, setChatClient] = useState(null);
    const [isChannel, setIsChannel] = useState(null);
    const [msgText, setMsgText] = useState('');

    const data = props.singleStreamData?.data;

    const client = StreamChat.getInstance('8d2z3akxmusr');

    useEffect(() => {
        const initChat = async (user) => {
            await client.connectUser(
                {
                    id: user?._id,
                    name: user?.fName,
                    image: user?.image,
                }, client.devToken(user?._id),
            );
            setChatClient(client);
            const channels = await client.channel('livestream', data?.streamChat?.id, {
                image: user?.image,
                name: 'Test',
            });

            setIsChannel(channels);

            // await channels.addMembers([user?._id]);
            await channels.watch();
        };

        if (userData)
            initChat(userData);

        // return () => { client.disconnectUser(); }
    }, [userData]);

    const handleSubmitMsg = async () => {
        if (msgText !== '') {
            try {
                await isChannel?.sendMessage({
                    text: msgText
                })
            } catch (error) {
                console.log("error on send message", error);
            }
        }
    }

    if (!chatClient || !isChannel) {
        return <></>;
    }

    return (
        <div className='liveStream-chat disable-chat'>

            <Chat client={chatClient} theme='messaging dark'>
                <Channel channel={isChannel}>
                    <Window>
                        <ChannelHeader title="CHAT" />
                        <MessageList
                            disableDateSeparator={true}
                            messageActions={[]}
                            actionsEnabled={false}
                        />
                        {data?.status !== 'finished' &&
                            <>
                                {props.loggedUser?.data?.role?.role !== "endUser" ?
                                    <span className="basic-text">
                                        <a href="/">Sign In to Continue Chat</a>
                                    </span>
                                    :
                                    <>
                                        <MessageInput
                                            focus
                                            noFiles={true}
                                            grow
                                        // overrideSubmitHandler={(message) => setMsgText(message)}
                                        />
                                        <SendButton />
                                        {/* <div className='sendMsg-Btn'>
                                            <button onClick={handleSubmitMsg}>
                                                <SendInpuIcon />
                                            </button>
                                        </div> */}
                                    </>
                                }
                            </>
                        }
                    </Window>
                </Channel>
            </Chat>


            {/* <div className='chat-header'>
                <p>Chat <span>(110)</span></p>
            </div>
            <div className='chat-body'>
                <div className='chat-list'>
                    <span className='chat-item'>
                        <img src="https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png" alt='' />
                        <div className='chat-text'>
                            <span className='chat-username'>Helen N.</span>
                            <span className='chat-message'>Hi everyone!</span>
                        </div>
                    </span>
                </div>
                <div className='chat-sendMsg'>
                    <div className='sendMsg-input'>
                        <input type='text' name='sendMessage' id='sendMessage' onChange={handleChange} />
                    </div>
                    <div className='sendMsg-Btn'>
                        <button onClick={handleSubmit}>
                            <SendInpuIcon />
                        </button>
                    </div>
                </div>
            </div> */}
        </div>
    )
}