import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import backendApi from './model/api';

// constants
export const authConstants = {
    LOGIN_AUTH_REQUEST_FOR_SEND_OTP: 'LOGIN_AUTH_REQUEST_FOR_SEND_OTP',
    LOGIN_SEND_OTP_REQ_FAILURE: 'LOGIN_SEND_OTP_REQ_FAILURE',
    LOGIN_SEND_OTP_REQ_RESPONSE: 'LOGIN_SEND_OTP_REQ_RESPONSE',
    LOGIN_VERIFY_OTP_REQ_RESPONSE: 'LOGIN_VERIFY_OTP_REQ_RESPONSE',
    LOGIN_VERIFY_OTP_REQ_FAILURE: 'LOGIN_VERIFY_OTP_REQ_FAILURE',
    LOGIN_AUTH_REQUEST_FOR_VERIFY_OTP: 'LOGIN_AUTH_REQUEST_FOR_VERIFY_OTP',
    LOGGED_IN_USER_REQ: 'LOGGED_IN_USER_REQ',
    LOGGED_IN_USER_REQ_SUCCESS: 'LOGGES_IN_USER_REQ_SUCCESS',
    LOGGED_IN_USER_REQ_FAILURE: 'LOGGED_IN_USER_REQ_FAILURE',
    LOGOUT_USER: 'LOGOUT_USER',
    LOGOUT_USER_SUCCESS: 'LOGOUT_USER_SUCCESS',
    LOGOUT_USER_FAILURE: 'LOGOUT_USER_FAILURE',
    GOOGLE_SSO_REQ: 'GOOGLE_SSO_REQ',
    GOOGLE_SSO_REQ_SUCESS: 'GOOGLE_SSO_REQ_SUCESS',
    GOOGLE_SSO_REQ_FAILURE: 'GOOGLE_SSO_REQ_FAILURE',

    THEME_CHANGE_REQ: 'THEME_CHANGE_REQ',

    GUEST_USER_LOGIN_REQ: 'GUEST_USER_LOGIN_REQ',
    GUEST_USER_LOGIN_SUCCESS: 'GUEST_USER_LOGIN_SUCCESS',
    GUEST_USER_LOGIN_FAILURE: 'GUEST_USER_LOGIN_FAILURE',

    ADD_NEW_ADDRESS_REQ: 'ADD_NEW_ADDRESS_REQ',
    ADD_NEW_ADDRESS_SUCCESS: 'ADD_NEW_ADDRESS_SUCCESS',
    ADD_NEW_ADDRESS_FAILURE: 'ADD_NEW_ADDRESS_FAILURE',

    EDIT_ADDRESS_REQ: 'EDIT_ADDRESS_REQ',
    EDIT_ADDRESS_SUCCESS: 'EDIT_ADDRESS_SUCCESS',
    EDIT_ADDRESS_FAILURE: 'EDIT_ADDRESS_FAILURE',

    UPDATE_PROFILE_DATA_REQ: 'UPDATE_PROFILE_DATA_REQ',
    UPDATE_PROFILE_DATA_SUCCESS: 'UPDATE_PROFILE_DATA_SUCCESS',
    UPDATE_PROFILE_DATA_FAILURE: 'UPDATE_PROFILE_DATA_FAILURE',

    SEND_OTP_FOR_MOBILE_REQ: 'SEND_OTP_FOR_MOBILE_REQ',
    SEND_OTP_FOR_MOBILE_SUCCESS: 'SEND_OTP_FOR_MOBILE_SUCCESS',
    SEND_OTP_FOR_MOBILE_FAILURE: 'SEND_OTP_FOR_MOBILE_FAILURE',

    VERIFY_OTP_FOR_MOBILE_REQ: 'VERIFY_OTP_FOR_MOBILE_REQ',
    VERIFY_OTP_FOR_MOBILE_SUCCESS: 'VERIFY_OTP_FOR_MOBILE_SUCCESS',
    VERIFY_OTP_FOR_MOBILE_FAILURE: 'VERIFY_OTP_FOR_MOBILE_FAILURE',
};

export const loginActions = {};
// actions

loginActions.logingetOtp = payload => ({
    type: authConstants.LOGIN_AUTH_REQUEST_FOR_SEND_OTP,
    payload,
});

loginActions.loginverifyOtp = payload => ({
    type: authConstants.LOGIN_AUTH_REQUEST_FOR_VERIFY_OTP,
    payload,
});

loginActions.isLoggedIn = payload => ({
    type: authConstants.LOGGED_IN_USER_REQ,
    payload,
});

loginActions.isLogout = payload => ({
    type: authConstants.LOGOUT_USER,
    payload,
});

loginActions.googleSSO = payload => ({
    type: authConstants.GOOGLE_SSO_REQ,
    payload,
});

loginActions.themeSwitcher = payload => ({
    type: authConstants.THEME_CHANGE_REQ,
    payload,
});

loginActions.guestLogin = payload => ({
    type: authConstants.GUEST_USER_LOGIN_REQ,
    payload,
})

loginActions.addNewAddress = payload => ({
    type: authConstants.ADD_NEW_ADDRESS_REQ,
    payload,
})

loginActions.editAddress = payload => ({
    type: authConstants.EDIT_ADDRESS_REQ,
    payload,
})

loginActions.editProfile = payload => ({
    type: authConstants.UPDATE_PROFILE_DATA_REQ,
    payload,
})

loginActions.sendOTPForMobile = payload => ({
    type: authConstants.SEND_OTP_FOR_MOBILE_REQ,
    payload,
})

loginActions.verifyOTPForMobile = payload => ({
    type: authConstants.VERIFY_OTP_FOR_MOBILE_REQ,
    payload,
})

// The initial state of the App
export const initialState = {
    loggedUser: '',
    otpResponse: null,
    verifyotpResponse: null,
    logged: 'wait',
    logoutUser: '',
    googleUser: '',
    isTheme: false,
    isGuestUser: '',
    googleLoader: false,
    newAddress: '',
    sendMobileOTPRes: '',
    verifyMobileOTPRes: '',
    editProfile: '',
};

// reducers
const appReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case authConstants.SEND_OTP_FOR_MOBILE_SUCCESS:
                draft.sendMobileOTPRes = action.payload;
                break;
            case authConstants.SEND_OTP_FOR_MOBILE_FAILURE:
                draft.sendMobileOTPRes = action.payload;
                break;
            case authConstants.VERIFY_OTP_FOR_MOBILE_SUCCESS:
                draft.verifyMobileOTPRes = action.payload;
                break;
            case authConstants.VERIFY_OTP_FOR_MOBILE_FAILURE:
                draft.verifyMobileOTPRes = action.payload;
                break;
            case authConstants.LOGIN_SEND_OTP_REQ_RESPONSE:
                draft.otpResponse = action.payload;
                break;
            case authConstants.LOGIN_SEND_OTP_REQ_FAILURE:
                draft.otpResponse = action.payload;
                break;
            case authConstants.LOGIN_VERIFY_OTP_REQ_RESPONSE:
                draft.verifyotpResponse = action.payload;
                draft.loggedUser = action.payload;
                break;
            case authConstants.LOGIN_VERIFY_OTP_REQ_FAILURE:
                draft.verifyotpResponse = action.payload;
                break;
            case authConstants.LOGGED_IN_USER_REQ_SUCCESS:
                draft.loggedUser = action.payload;
                draft.logged = 'pass';
                break;
            case authConstants.LOGGED_IN_USER_REQ_FAILURE:
                draft.loggedUser = action.payload;
                draft.logged = 'fail';
                break;
            case authConstants.LOGOUT_USER_SUCCESS:
                draft.logoutUser = action.payload;
                break;
            case authConstants.LOGOUT_USER_FAILURE:
                draft.logoutUser = action.payload;
                break;
            case authConstants.GOOGLE_SSO_REQ:
                draft.googleLoader = true;
                break;
            case authConstants.GOOGLE_SSO_REQ_SUCESS:
                draft.googleUser = action.payload;
                draft.googleLoader = false;
                break;
            case authConstants.GOOGLE_SSO_REQ_FAILURE:
                draft.googleUser = action.payload;
                draft.googleLoader = false;
                break;
            case authConstants.THEME_CHANGE_REQ:
                draft.isTheme = action.payload;
                break;
            case authConstants.GUEST_USER_LOGIN_SUCCESS:
                draft.isGuestUser = action.payload;
                draft.logged = 'pass';
                break;
            case authConstants.GUEST_USER_LOGIN_FAILURE:
                draft.isGuestUser = action.payload;
                draft.logged = 'fail';
                break;
            case authConstants.ADD_NEW_ADDRESS_SUCCESS:
                draft.newAddress = action.payload;
                break;
            case authConstants.ADD_NEW_ADDRESS_FAILURE:
                draft.newAddress = action.payload;
                break;
            case authConstants.EDIT_ADDRESS_SUCCESS:
                draft.newAddress = action.payload;
                break;
            case authConstants.EDIT_ADDRESS_FAILURE:
                draft.newAddress = action.payload;
                break;
            case authConstants.UPDATE_PROFILE_DATA_SUCCESS:
                draft.editProfile = action.payload;
                break;
            case authConstants.UPDATE_PROFILE_DATA_FAILURE:
                draft.editProfile = action.payload;
                break;
            default:
                break;
        }
    });
export default appReducer;

export const getAuthState = state => state.reg;

function* logingetOTP(props) {
    try {
        const res = yield backendApi.loginsendOtpApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.LOGIN_SEND_OTP_REQ_RESPONSE, payload: res });
        else
            yield put({ type: authConstants.LOGIN_SEND_OTP_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.LOGIN_SEND_OTP_REQ_FAILURE, payload: false });
    }
}

function* loginverifyOTP(props) {
    try {
        const res = yield backendApi.loginverifyOTPApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.LOGIN_VERIFY_OTP_REQ_RESPONSE, payload: res });
        else
            yield put({ type: authConstants.LOGIN_VERIFY_OTP_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.LOGIN_VERIFY_OTP_REQ_FAILURE, payload: false });
    }
}

function* loggedUser(props) {
    try {
        const res = yield backendApi.loggedAPIReq(props.payload);

        if (res.success)
            yield put({ type: authConstants.LOGGED_IN_USER_REQ_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.LOGGED_IN_USER_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.LOGGED_IN_USER_REQ_FAILURE, payload: false });
    }
}

function* logoutReq(props) {
    try {
        const res = yield backendApi.logoutAPI(props.payload);

        if (res.success)
            yield put({ type: authConstants.LOGOUT_USER_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.LOGOUT_USER_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.LOGOUT_USER_FAILURE, payload: false });
    }
}

function* googleSSOReq(props) {
    try {
        const res = yield backendApi.googleSSOApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.GOOGLE_SSO_REQ_SUCESS, payload: res });
        else
            yield put({ type: authConstants.GOOGLE_SSO_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.GOOGLE_SSO_REQ_FAILURE, payload: false });
    }
}

function* guestLoginReq(props) {
    try {
        const res = yield backendApi.guestLoginApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.GUEST_USER_LOGIN_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.GUEST_USER_LOGIN_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.GUEST_USER_LOGIN_FAILURE, payload: false });
    }
}

function* addNewAddressReq(props) {
    try {
        const res = yield backendApi.addNewAddressApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.ADD_NEW_ADDRESS_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.ADD_NEW_ADDRESS_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.ADD_NEW_ADDRESS_FAILURE, payload: false });
    }
}

function* updateAddressReq(props) {
    try {
        const res = yield backendApi.updateAddressApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.EDIT_ADDRESS_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.EDIT_ADDRESS_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.EDIT_ADDRESS_FAILURE, payload: false });
    }
}

function* editProfileReq(props) {
    try {
        const res = yield backendApi.updateProfileApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.UPDATE_PROFILE_DATA_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.UPDATE_PROFILE_DATA_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.UPDATE_PROFILE_DATA_FAILURE, payload: false });
    }
}

function* sendMobileOTP(props) {
    try {
        const res = yield backendApi.sendOTPForMobileApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.SEND_OTP_FOR_MOBILE_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.SEND_OTP_FOR_MOBILE_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.SEND_OTP_FOR_MOBILE_FAILURE, payload: false });
    }
}

function* verifyMobileOTP(props) {
    try {
        const res = yield backendApi.verifyOTPForMobileApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.VERIFY_OTP_FOR_MOBILE_SUCCESS, payload: res });
        else
            yield put({ type: authConstants.VERIFY_OTP_FOR_MOBILE_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.VERIFY_OTP_FOR_MOBILE_FAILURE, payload: false });
    }
}

export const LoginSagas = [
    takeLatest(authConstants.LOGIN_AUTH_REQUEST_FOR_SEND_OTP, logingetOTP),
    takeLatest(authConstants.LOGIN_AUTH_REQUEST_FOR_VERIFY_OTP, loginverifyOTP),
    takeLatest(authConstants.LOGGED_IN_USER_REQ, loggedUser),
    takeLatest(authConstants.LOGOUT_USER, logoutReq),
    takeLatest(authConstants.GOOGLE_SSO_REQ, googleSSOReq),
    takeLatest(authConstants.GUEST_USER_LOGIN_REQ, guestLoginReq),
    takeLatest(authConstants.ADD_NEW_ADDRESS_REQ, addNewAddressReq),
    takeLatest(authConstants.EDIT_ADDRESS_REQ, updateAddressReq),
    takeLatest(authConstants.UPDATE_PROFILE_DATA_REQ, editProfileReq),
    takeLatest(authConstants.SEND_OTP_FOR_MOBILE_REQ, sendMobileOTP),
    takeLatest(authConstants.VERIFY_OTP_FOR_MOBILE_REQ, verifyMobileOTP),
];