import React, { useState, useEffect } from "react";
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
    showMsg: {
        position: 'fixed',
        color: '#ffffff',
        zIndex: '9999',
        background: 'linear-gradient(147.67deg, #43B028 24.24%, #FBCE2E 145.04%)',
        padding: '0.5rem 3rem',
        bottom: 20,
        left: 20,
        borderRadius: 5,
    },
    msgText: {
        margin: 0,
        fontSize: '0.9rem'
    }
});

const Message = (props) => {
    const classes = useStyles();

    const [show, setIsShow] = useState(false);

    useEffect(() => {
        if (props.data?.data?.message)
            setIsShow(true);
        else if (props.data?.message)
            setIsShow(true);
    }, [props.data]);

    useEffect(() => {
        setTimeout(() => setIsShow(false), 4000);
    }, [show])

    return (
        <>
            {show ?
                <div className={classes.showMsg}>
                    <p className={classes.msgText}>{props.data?.message ? props.data?.message : props.data?.data?.message}</p>
                </div>
                :
                ''
            }
        </>
    );
}

export default Message;