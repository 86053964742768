import React, { useEffect, useRef, useState } from "react";
import '../Video/style.css'
import {
    VideoIcon,
    ShoppingBagIcon,
    HeartFillIcon,
    HeartLineIcon,
} from "../../sharedUtils/globalIcons"
import moment from 'moment'
import { Link, useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';
import Modal from '../Modal';
import Message from '../../sharedUtils/globalMessage';

export default function Video(props) {
    const vidRef = useRef(null);

    const history = useHistory()
    const data = props.singleStreamData.data;

    const [videoUrl, setVideoUrl] = useState('');
    const [imgUrl, setImgUrl] = useState('');
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [message, setMessage] = useState();
    const [isLike, setIsLike] = useState(data?.isLike);

    useEffect(() => {
        if (data?.url) {
            setTimeout(() => {
                document.getElementById('streamVideo')?.play()
            }, 3000)
        }
    }, [data?.url])

    // const playVideo = () => {
    //     vidRef.current.play();
    // }
    useEffect(() => {
        if (data?.status == 'broadcasting') {
            setVideoUrl(data?.viewURL)
        }
        if (data?.status == 'finished' || data?.status == 'promoted') {
            setVideoUrl(data?.streamVideo)
        }
        if (data?.status == 'created') {
            if (data?.video) {
                setVideoUrl(data?.video)
            } else {
                setImgUrl(data?.image)
            }
        }
    }, [data?.status])

    useEffect(() => {
        if (!props.singleStreamData?.success)
            setModal2(true);
    }, [props.singleStreamData?.success])

    const BackIcon = () => {
        return (
            <svg viewBox="0 0 512 512">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M328 112L184 256l144 144" />
            </svg>
        )
    }

    const handleVideoError = () => {
        setModal(true);
    }

    const handleVideoEnded = () => {
        setModal(true);
    }

    const handleLikes = () => {
        setIsLike(prevState => !prevState);

        const obj = {
            _id: data?._id,
            like: !isLike,
        }

        props._likeLiveStream(obj);
    }

    console.log("props.singleStreamData", props.singleStreamData)

    return (
        <div className='liveStream-video'>
            <div className="backBtn">
                <button onClick={history.goBack}><BackIcon /></button>
            </div>

            <div className="live_video">
                <div className="videoHeader">
                    <div className="video-content">
                        <Link
                            to={{
                                pathname: `/shop/${data?.shopId?.slug}`,
                            }}
                        >
                            <span className='iconLabel'>
                                <img src={data?.shopId?.image} alt="" /> <span className="overflow-text" title={data?.shopId?.name}>{data?.shopId?.name}</span>
                            </span>
                        </Link>
                    </div>

                    <div className="video-status">
                        {/* <span className='live-views'><EyeIcon /> {data?.viewersCount}</span> */}
                        {data?.status === 'broadcasting' ?
                            <span className='live-status'><VideoIcon /> Live</span>
                            :
                            <>
                                {data?.status === 'promoted' ?
                                    <span className='video-onAir'><VideoIcon /> Promoted</span>
                                    :
                                    <span className='live-views'>{moment(data?.startDateAndTime).format('MMM DD, h:mm a')}</span>
                                }
                            </>
                        }
                        {/* <span className='close-btn'><CloseIcon /></span> */}
                    </div>

                    <div className="video-content" style={{ width: '100%' }}>
                        <span className='iconImg'>
                            {/* <img src={data?.image} alt='' /> */}
                            <p>{data?.name}</p>
                        </span>
                    </div>
                    <div className="video-content" style={{ width: '100%' }}>
                        {data?.streamer?.role?.role === "influencer" ?
                            <Link
                                to={{
                                    pathname: `/influencer`,
                                    state: {
                                        data: data,
                                    }
                                }}
                            >
                                <span className='iconImg streamer_info'>
                                    <img src={data?.streamer?.image} alt='' />
                                    <p>{data?.streamer?.fName}</p>
                                </span>
                            </Link>
                            :
                            <Link
                                to={{
                                    pathname: `/shop/${data?.shopId?.slug}`,
                                }}
                            >
                                <span className='iconImg streamer_info'>
                                    <img src={data?.streamer?.image} alt='' />
                                    <p>{data?.streamer?.fName}</p>
                                </span>
                            </Link>
                        }
                    </div>
                </div>

                {data?.status === "broadcasting" ?
                    <ReactPlayer
                        // volume={isVolume}
                        controls={true}
                        playing={true}
                        loop={false}
                        ref={player => player = player}
                        onPlay={() => setIsVideoPlaying(true)}
                        onPause={() => setIsVideoPlaying(false)}
                        onError={() => handleVideoError()}
                        onEnded={() => handleVideoEnded()}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload noplaybackrate',
                                    disablePictureInPicture: 'true',
                                }
                            }
                        }}
                        width="100%"
                        height="100%"
                        url={videoUrl}
                        pip={false}
                    />
                    :
                    <ReactPlayer
                        // volume={isVolume}
                        controls={true}
                        playing={true}
                        loop
                        ref={player => player = player}
                        onPlay={() => setIsVideoPlaying(true)}
                        onPause={() => setIsVideoPlaying(false)}
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload noplaybackrate',
                                    disablePictureInPicture: 'true',
                                }
                            }
                        }}
                        width="100%"
                        height="100%"
                        url={videoUrl}
                        pip={false}
                    />
                }

                <div className='live-actionBtn'>
                    {data?.externalUrl ?
                        <span className='live-cart'><a
                            href={data?.externalUrl?.includes('http') ? data.externalUrl : 'https://'
                                + data.externalUrl} target="_blank"><ShoppingBagIcon /></a></span>
                        : null
                    }

                    {props.isGuestUser?.data?.isLoggedIn === false ?
                        ''
                        :
                        <span className='live-likes' onClick={handleLikes}>
                            {isLike ?
                                <HeartFillIcon />
                                :
                                <HeartLineIcon />
                            }
                            {/* <p>346</p> */}
                        </span>
                    }
                    {/* <span className='live-share'><ShareIconGreen /> <p>217</p></span> */}
                </div>
            </div>

            <Modal onClose={() => setModal(false)} show={modal} from='LiveStream'>
                <div className="stream_ended_popup">
                    <h3>Stream Ended!<br /> Please go back to Home Page.</h3>
                    <a href="/">Go Back</a>
                </div>
            </Modal>

            <Modal onClose={() => setModal2(false)} show={modal2} from='LiveStream'>
                <div className="stream_ended_popup">
                    <h3>Stream not available!<br />Please click the homepage button</h3>
                    <a href="/">Homepage</a>
                </div>
            </Modal>

            <Message data={message} />

        </div>
    )
};