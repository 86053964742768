import backend from '../../../sharedUtils/backend';

export default new (class authAPI extends backend {
    constructor() {
        super();
    }

    sendOtp(data) {
        return this.post('auth/register/send-otp', data);
    }

    verifyOTPApi(data){
        return this.post('auth/register/endUser', data);
    }

})();
