import produce from 'immer';
import { put, takeLatest } from 'redux-saga/effects';
import backendApi from './model/api';

// constants
export const authConstants = {
    AUTH_REQUEST: 'AUTH_REQUEST',
    AUTH_SUCCESS: 'AUTH_SUCCESS',
    AUTH_FAILURE: 'AUTH_FAILURE',
    AUTH_REQUEST_FOR_SEND_OTP: 'AUTH_REQUEST_FOR_SEND_OTP',
    SEND_OTP_REQ_FAILURE: 'SEND_OTP_REQ_FAILURE',
    SEND_OTP_REQ_RESPONSE: 'SEND_OTP_REQ_RESPONSE',
    VERIFY_OTP_REQ_RESPONSE: 'VERIFY_OTP_REQ_RESPONSE',
    VERIFY_OTP_REQ_FAILURE: 'VERIFY_OTP_REQ_FAILURE',
    AUTH_REQUEST_FOR_VERIFY_OTP: 'AUTH_REQUEST_FOR_VERIFY_OTP',
};

export const authActions = {};
// actions

authActions.getOtp = payload => ({
    type: authConstants.AUTH_REQUEST_FOR_SEND_OTP,
    payload,
});

authActions.verifyOtp = payload => ({
    type: authConstants.AUTH_REQUEST_FOR_VERIFY_OTP,
    payload,
});

// The initial state of the App
export const initialState = {
    regloggedUser: '',
    sendOtpRes: null,
    regVerOtp: null,
};

// reducers
const appReducer = (state = initialState, action) =>
    produce(state, draft => {
        switch (action.type) {
            case authConstants.SEND_OTP_REQ_RESPONSE:
                draft.sendOtpRes = action.payload;
                break;
            case authConstants.SEND_OTP_REQ_FAILURE:
                draft.sendOtpRes = action.payload;
                break;
            case authConstants.VERIFY_OTP_REQ_RESPONSE:
                draft.regVerOtp = action.payload;
                draft.regloggedUser = action.payload;
                break;
            case authConstants.VERIFY_OTP_REQ_FAILURE:
                draft.regVerOtp = action.payload;
                break;
            default:
                break;
        }
    });
export default appReducer;

export const getAuthState = state => state.reg;

function* getOTP(props) {
    try {
        const res = yield backendApi.sendOtp(props.payload);

        if (res.success)
            yield put({ type: authConstants.SEND_OTP_REQ_RESPONSE, payload: res });
        else
            yield put({ type: authConstants.SEND_OTP_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.SEND_OTP_REQ_FAILURE, payload: false });
    }
}

function* verifyOTP(props) {
    try {
        const res = yield backendApi.verifyOTPApi(props.payload);

        if (res.success)
            yield put({ type: authConstants.VERIFY_OTP_REQ_RESPONSE, payload: res });
        else
            yield put({ type: authConstants.VERIFY_OTP_REQ_FAILURE, payload: res });

    } catch (error) {
        yield put({ type: authConstants.VERIFY_OTP_REQ_FAILURE, payload: false });
    }
}

export const AuthSagas = [
    takeLatest(authConstants.AUTH_REQUEST_FOR_SEND_OTP, getOTP),
    takeLatest(authConstants.AUTH_REQUEST_FOR_VERIFY_OTP, verifyOTP),
];