import React from "react";
import '../App.css'

export const VideoIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.25 6V12H3.75V6H11.25ZM12 4.5H3C2.5875 4.5 2.25 4.8375 2.25 5.25V12.75C2.25 13.1625 2.5875 13.5 3 13.5H12C12.4125 13.5 12.75 13.1625 12.75 12.75V10.125L15.75 13.125V4.875L12.75 7.875V5.25C12.75 4.8375 12.4125 4.5 12 4.5Z" fill="white" />
        </svg>
    );
}

export const EyeIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 4.5C11.8425 4.5 14.3775 6.0975 15.615 8.625C14.3775 11.1525 11.8425 12.75 9 12.75C6.1575 12.75 3.6225 11.1525 2.385 8.625C3.6225 6.0975 6.1575 4.5 9 4.5ZM9 3C5.25 3 2.0475 5.3325 0.75 8.625C2.0475 11.9175 5.25 14.25 9 14.25C12.75 14.25 15.9525 11.9175 17.25 8.625C15.9525 5.3325 12.75 3 9 3ZM9 6.75C10.035 6.75 10.875 7.59 10.875 8.625C10.875 9.66 10.035 10.5 9 10.5C7.965 10.5 7.125 9.66 7.125 8.625C7.125 7.59 7.965 6.75 9 6.75ZM9 5.25C7.14 5.25 5.625 6.765 5.625 8.625C5.625 10.485 7.14 12 9 12C10.86 12 12.375 10.485 12.375 8.625C12.375 6.765 10.86 5.25 9 5.25Z" fill="white" />
        </svg>
    )
}

export const ShareIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08ZM18 4C18.55 4 19 4.45 19 5C19 5.55 18.55 6 18 6C17.45 6 17 5.55 17 5C17 4.45 17.45 4 18 4ZM6 13C5.45 13 5 12.55 5 12C5 11.45 5.45 11 6 11C6.55 11 7 11.45 7 12C7 12.55 6.55 13 6 13ZM18 20.02C17.45 20.02 17 19.57 17 19.02C17 18.47 17.45 18.02 18 18.02C18.55 18.02 19 18.47 19 19.02C19 19.57 18.55 20.02 18 20.02Z" fill="white" />
        </svg>
    )
}

export const BookmarkIcon = () => {
    return (
        <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 0H2C0.9 0 0 0.9 0 2V18L7 15L14 18V2C14 0.9 13.1 0 12 0ZM12 15L7 12.82L2 15V2H12V15Z" fill="white" />
        </svg>
    )
}

export const CallIcon = () => {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 15.9201V18.9201C21.0011 19.1986 20.9441 19.4743 20.8325 19.7294C20.7209 19.9846 20.5573 20.2137 20.3521 20.402C20.1468 20.5902 19.9046 20.7336 19.6407 20.8228C19.3769 20.912 19.0974 20.9452 18.82 20.9201C15.7428 20.5857 12.787 19.5342 10.19 17.8501C7.77382 16.3148 5.72533 14.2663 4.18999 11.8501C2.49997 9.2413 1.44824 6.27109 1.11999 3.1801C1.095 2.90356 1.12787 2.62486 1.21649 2.36172C1.30512 2.09859 1.44756 1.85679 1.63476 1.65172C1.82196 1.44665 2.0498 1.28281 2.30379 1.17062C2.55777 1.05843 2.83233 1.00036 3.10999 1.0001H6.10999C6.5953 0.995321 7.06579 1.16718 7.43376 1.48363C7.80173 1.80008 8.04207 2.23954 8.10999 2.7201C8.23662 3.68016 8.47144 4.62282 8.80999 5.5301C8.94454 5.88802 8.97366 6.27701 8.8939 6.65098C8.81415 7.02494 8.62886 7.36821 8.35999 7.6401L7.08999 8.9101C8.51355 11.4136 10.5864 13.4865 13.09 14.9101L14.36 13.6401C14.6319 13.3712 14.9751 13.1859 15.3491 13.1062C15.7231 13.0264 16.1121 13.0556 16.47 13.1901C17.3773 13.5286 18.3199 13.7635 19.28 13.8901C19.7658 13.9586 20.2094 14.2033 20.5265 14.5776C20.8437 14.9519 21.0122 15.4297 21 15.9201Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const FacebookIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 2H15C13.6739 2 12.4021 2.52678 11.4645 3.46447C10.5268 4.40215 10 5.67392 10 7V10H7V14H10V22H14V14H17L18 10H14V7C14 6.73478 14.1054 6.48043 14.2929 6.29289C14.4804 6.10536 14.7348 6 15 6H18V2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const InstagramIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.765 2H7.76502C5.00359 2 2.76501 4.23858 2.76501 7V17C2.76501 19.7614 5.00359 22 7.76502 22H17.765C20.5264 22 22.765 19.7614 22.765 17V7C22.765 4.23858 20.5264 2 17.765 2Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.765 11.3698C16.8884 12.2021 16.7463 13.052 16.3588 13.7988C15.9713 14.5456 15.3582 15.1512 14.6067 15.5295C13.8551 15.9077 13.0035 16.0394 12.1728 15.9057C11.3422 15.7721 10.5748 15.3799 9.97987 14.785C9.38494 14.1901 8.99276 13.4227 8.85909 12.592C8.72543 11.7614 8.85709 10.9097 9.23535 10.1582C9.61361 9.40667 10.2192 8.79355 10.966 8.40605C11.7128 8.01856 12.5628 7.8764 13.395 7.99981C14.244 8.1257 15.0299 8.52128 15.6367 9.12812C16.2436 9.73496 16.6391 10.5209 16.765 11.3698Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M18.265 6.5H18.275" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const CopyRightIcon = () => {
    return (
        <svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.5 0C5.1588 0 0 4.48591 0 9.99996C0 15.514 5.1588 19.9999 11.5 19.9999C17.8411 19.9999 22.9999 15.514 22.9999 9.99996C22.9999 4.48591 17.8411 0 11.5 0ZM11.5 18.699C5.98383 18.699 1.49612 14.7967 1.49612 9.99996C1.49612 5.20326 5.98383 1.30098 11.5 1.30098C17.0161 1.30098 21.5038 5.20333 21.5038 9.99996C21.5038 14.7966 17.0162 18.699 11.5 18.699Z" fill="white" />
            <path d="M16.1929 11.6292C15.8448 11.4358 15.3822 11.5242 15.1595 11.8267C14.4368 12.8092 13.2058 13.3957 11.8666 13.3957C9.71303 13.3957 7.96102 11.8722 7.96102 9.9997C7.96102 8.12716 9.71303 6.60371 11.8666 6.60371C13.1661 6.60371 14.377 7.16364 15.1058 8.1015C15.337 8.39906 15.8019 8.47741 16.1445 8.27626C16.4868 8.07519 16.5768 7.6708 16.3455 7.3731C15.3382 6.07667 13.6637 5.30273 11.8666 5.30273C8.88802 5.30273 6.46479 7.40974 6.46479 9.9997C6.46479 12.5897 8.88802 14.6967 11.8666 14.6967C13.7187 14.6967 15.4209 13.8859 16.42 12.5277C16.6426 12.2251 16.5409 11.8229 16.1929 11.6292Z" fill="white" />
        </svg>
    )
}

export const ArrowUp = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 19V5" stroke="#43B028" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.99994 12L11.9999 5L18.9998 12" stroke="#43B028" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const CloseIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M25.3333 8.5465L23.4533 6.6665L16 14.1198L8.54663 6.6665L6.66663 8.5465L14.12 15.9998L6.66663 23.4532L8.54663 25.3332L16 17.8798L23.4533 25.3332L25.3333 23.4532L17.88 15.9998L25.3333 8.5465Z" fill="white" />
        </svg>
    )
}

export const ShareIconGreen = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#F2F2F2" />
            <path d="M30 28.08C29.24 28.08 28.56 28.38 28.04 28.85L20.91 24.7C20.96 24.47 21 24.24 21 24C21 23.76 20.96 23.53 20.91 23.3L27.96 19.19C28.5 19.69 29.21 20 30 20C31.66 20 33 18.66 33 17C33 15.34 31.66 14 30 14C28.34 14 27 15.34 27 17C27 17.24 27.04 17.47 27.09 17.7L20.04 21.81C19.5 21.31 18.79 21 18 21C16.34 21 15 22.34 15 24C15 25.66 16.34 27 18 27C18.79 27 19.5 26.69 20.04 26.19L27.16 30.35C27.11 30.56 27.08 30.78 27.08 31C27.08 32.61 28.39 33.92 30 33.92C31.61 33.92 32.92 32.61 32.92 31C32.92 29.39 31.61 28.08 30 28.08Z" fill="#43B028" />
        </svg>
    )
}

export const HeartFillIcon = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 22.22L10.26 20.636C4.08 15.032 0 11.336 0 6.80001C0 3.10401 2.904 0.200012 6.6 0.200012C8.688 0.200012 10.692 1.17201 12 2.70801C13.308 1.17201 15.312 0.200012 17.4 0.200012C21.096 0.200012 24 3.10401 24 6.80001C24 11.336 19.92 15.032 13.74 20.648L12 22.22Z" fill="#43B028" />
        </svg>
    )
}

export const HeartLineIcon = () => {
    return (
        <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.9332 19.8965L10.9317 19.8952C7.82162 17.075 5.32134 14.8053 3.58655 12.6844C1.86324 10.5775 1 8.73953 1 6.80001C1 3.6563 3.45628 1.20001 6.6 1.20001C8.38521 1.20001 10.1142 2.03595 11.2386 3.35635L12 4.25042L12.7614 3.35635C13.8858 2.03595 15.6148 1.20001 17.4 1.20001C20.5437 1.20001 23 3.6563 23 6.80001C23 8.73954 22.1368 10.5775 20.4132 12.6862C18.6784 14.8086 16.1783 17.081 13.0684 19.9071C13.0681 19.9074 13.0678 19.9077 13.0675 19.9079L12.0026 20.87L10.9332 19.8965Z" stroke="#43B028" stroke-width="2" />
        </svg>
    )
}

export const SendInpuIcon = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="24" cy="24" r="24" fill="#E7E7E7" />
            <path d="M13.3452 34.5L37.8335 24L13.3452 13.5L13.3335 21.6667L30.8335 24L13.3335 26.3333L13.3452 34.5Z" fill="#43B028" />
        </svg>
    )
}

export const ThemeToggleIcon = () => {
    return (
        <svg version="1.1" viewBox="0 0 512 512" style={{ width: 30 }}>
            <g id="Layer_1" /><g id="Layer_2"><g><g><g><path className="st0" d="M256,119.81c-75.22,0-136.19,60.97-136.19,136.19S180.78,392.19,256,392.19S392.19,331.22,392.19,256      S331.22,119.81,256,119.81z M256,337.04c-44.76,0-81.04-36.28-81.04-81.04s36.28-81.04,81.04-81.04V337.04z" /></g></g><g><g><path className="st0" d="M256,86.02c-8.84,0-16-7.16-16-16V43.5c0-8.84,7.16-16,16-16s16,7.16,16,16v26.52      C272,78.86,264.84,86.02,256,86.02z" /></g></g><g><g><path className="st0" d="M387.51,140.49c-4.09,0-8.19-1.56-11.31-4.69c-6.25-6.25-6.25-16.38,0-22.63l18.75-18.75      c6.25-6.25,16.38-6.25,22.63,0c6.25,6.25,6.25,16.38,0,22.63l-18.75,18.75C395.7,138.93,391.6,140.49,387.51,140.49z" /></g></g><g><g><path className="st0" d="M468.5,272h-26.52c-8.84,0-16-7.16-16-16s7.16-16,16-16h26.52c8.84,0,16,7.16,16,16S477.34,272,468.5,272z" /></g></g><g><g><path className="st0" d="M406.26,422.26c-4.09,0-8.19-1.56-11.31-4.69l-18.75-18.75c-6.25-6.25-6.25-16.38,0-22.63      c6.25-6.25,16.38-6.25,22.63,0l18.75,18.75c6.25,6.25,6.25,16.38,0,22.63C414.45,420.7,410.35,422.26,406.26,422.26z" /></g></g><g><g><path className="st0" d="M256,484.5c-8.84,0-16-7.16-16-16v-26.52c0-8.84,7.16-16,16-16s16,7.16,16,16v26.52      C272,477.34,264.84,484.5,256,484.5z" /></g></g><g><g><path className="st0" d="M105.74,422.26c-4.09,0-8.19-1.56-11.31-4.69c-6.25-6.25-6.25-16.38,0-22.63l18.75-18.75      c6.25-6.25,16.38-6.25,22.63,0c6.25,6.25,6.25,16.38,0,22.63l-18.75,18.75C113.93,420.7,109.83,422.26,105.74,422.26z" /></g></g><g><g><path className="st0" d="M70.02,272H43.5c-8.84,0-16-7.16-16-16s7.16-16,16-16h26.52c8.84,0,16,7.16,16,16S78.86,272,70.02,272z" /></g></g><g><g><path className="st0" d="M124.49,140.49c-4.09,0-8.19-1.56-11.31-4.69l-18.75-18.75c-6.25-6.25-6.25-16.38,0-22.63      c6.25-6.25,16.38-6.25,22.63,0l18.75,18.75c6.25,6.25,6.25,16.38,0,22.63C132.68,138.93,128.59,140.49,124.49,140.49z" /></g></g></g></g>
        </svg>
    )
}

export const RightArrowIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 21 }}>
            <title>Chevron Forward</title>
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M184 112l144 144-144 144" />
        </svg>
    )
}

export const GoogleIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="144px" height="144px">
            <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z" />
            <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z" />
            <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z" />
            <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z" />
        </svg>
    )
}

export const NotificationIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style={{ width: 18 }}>
            <title>Notifications</title>
            <path d="M440.08 341.31c-1.66-2-3.29-4-4.89-5.93-22-26.61-35.31-42.67-35.31-118 0-39-9.33-71-27.72-95-13.56-17.73-31.89-31.18-56.05-41.12a3 3 0 01-.82-.67C306.6 51.49 282.82 32 256 32s-50.59 19.49-59.28 48.56a3.13 3.13 0 01-.81.65c-56.38 23.21-83.78 67.74-83.78 136.14 0 75.36-13.29 91.42-35.31 118-1.6 1.93-3.23 3.89-4.89 5.93a35.16 35.16 0 00-4.65 37.62c6.17 13 19.32 21.07 34.33 21.07H410.5c14.94 0 28-8.06 34.19-21a35.17 35.17 0 00-4.61-37.66zM256 480a80.06 80.06 0 0070.44-42.13 4 4 0 00-3.54-5.87H189.12a4 4 0 00-3.55 5.87A80.06 80.06 0 00256 480z" />
        </svg>
    )
}

export const MapMarkerIcon = () => {
    return (
        <svg viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 8C5.175 8 4.5 7.325 4.5 6.5C4.5 5.675 5.175 5 6 5C6.825 5 7.5 5.675 7.5 6.5C7.5 7.325 6.825 8 6 8ZM10.5 6.65C10.5 3.9275 8.5125 2 6 2C3.4875 2 1.5 3.9275 1.5 6.65C1.5 8.405 2.9625 10.73 6 13.505C9.0375 10.73 10.5 8.405 10.5 6.65ZM6 0.5C9.15 0.5 12 2.915 12 6.65C12 9.14 9.9975 12.0875 6 15.5C2.0025 12.0875 0 9.14 0 6.65C0 2.915 2.85 0.5 6 0.5Z" fill="#43B028" />
        </svg>
    )
}

export const NoVideoIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M336 179.52A67.52 67.52 0 00268.48 112h-79.2a4 4 0 00-2.82 6.83l142.71 142.71a4 4 0 006.83-2.82zM16 180v152a68 68 0 0068 68h184a67.66 67.66 0 0042.84-15.24 4 4 0 00.33-6L54.41 122a4 4 0 00-4.87-.62A68 68 0 0016 180zM464 384.39a32 32 0 01-13-2.77 15.77 15.77 0 01-2.71-1.54l-82.71-58.22A32 32 0 01352 295.7v-79.4a32 32 0 0113.58-26.16l82.71-58.22a15.77 15.77 0 012.71-1.54 32 32 0 0145 29.24v192.76a32 32 0 01-32 32z" />
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M416 416L80 80" />
        </svg>
    )
}

export const ShoppingBagIcon = () => {
    return (
        <svg width="30" height="40" viewBox="0 0 30 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.8378 38.5977L27.041 12.2289C26.9726 11.5837 26.4283 11.0942 25.7796 11.0942H22.0048V7.00501C22.0049 3.14241 18.8624 0 14.9999 0C11.1374 0 7.99491 3.14241 7.99491 7.00501V11.0942H4.22016C3.57141 11.0942 3.02716 11.5837 2.95874 12.2289L0.161984 38.5977C0.0826246 39.3459 0.669533 40 1.42341 40H28.5764C29.3289 40 29.9173 39.3473 29.8378 38.5977ZM10.5319 7.00501C10.5319 4.54131 12.5362 2.53695 14.9999 2.53695C17.4636 2.53695 19.468 4.54131 19.468 7.00501V11.0942H10.5319V7.00501ZM9.7309 18.0809C8.99177 18.0809 8.39266 17.4818 8.39266 16.7426C8.39266 16.0035 8.99177 15.4044 9.7309 15.4044C10.47 15.4044 11.0691 16.0036 11.0691 16.7426C11.0691 17.4817 10.47 18.0809 9.7309 18.0809ZM20.2689 18.0809C19.5297 18.0809 18.9306 17.4818 18.9306 16.7426C18.9306 16.0035 19.5297 15.4044 20.2689 15.4044C21.008 15.4044 21.6071 16.0036 21.6071 16.7426C21.6071 17.4817 21.008 18.0809 20.2689 18.0809Z" fill="#43B028" />
        </svg>
    )
}

export const CameraIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>Camera</title><path d="M350.54 148.68l-26.62-42.06C318.31 100.08 310.62 96 302 96h-92c-8.62 0-16.31 4.08-21.92 10.62l-26.62 42.06C155.85 155.23 148.62 160 140 160H80a32 32 0 00-32 32v192a32 32 0 0032 32h352a32 32 0 0032-32V192a32 32 0 00-32-32h-59c-8.65 0-16.85-4.77-22.46-11.32z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><circle cx="256" cy="272" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" /><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M124 158v-22h-24v22" /></svg>
    )
}